// Switch navigation
// =================
// Navigation on the top of page
.c-nav-switch {
  $ctx: 13px; // set em context
  $b: &; // set block context

  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: em($ctx);

  // current module block
  &__state {
    display: flex;
    position: relative;
    padding: rem(16px) rem(30px);
    width: 100%;
    line-height: 1;
    background: color(primary, $colors-brand);

    @include themify($themes: $colors-variant) {
      background-color: theme(primary);
    }

    &,
    &:hover,
    &:focus,
    &:active {
      color: color(white);
    }

    &:focus {
      .o-icon-arrow {
        transform: rotate(-90deg);
      }
    }

    @include media-query(min-width sm) {
      display: flex;
    }

    @include media-range(phone) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__active {
    @include media-query(sm) {
      flex: 1;
    }

    @include media-range(phone) {
      display: block;
      text-align: center;
      padding-left: gutter(map-get($susy-responsive, xs)) * 4;
      padding-right: gutter(map-get($susy-responsive, xs)) * 4;
    }
  }

  &__toggle {
    @include media-range(phone) {
      position: absolute;
      top: 0;
      bottom: 0;
      height: rem(13px);
      right: gutter(map-get($susy-responsive, xs)) * 2;
      margin: auto;
    }

    .o-icon {
      margin-left: rem(10px);
      width: rem(20px);
      height: rem(14px);
    }
  }

  &__positions {
    height: 0;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    order: -1;
    will-change: height;
  }

  // list of links
  &__list {
    @include list-unstyle;

    display: flex;
    overflow: hidden;

    @include media-query(sm) {
      border-bottom: 1px solid color(porcelain);
    }

    @include media-range(phone) {
      display: block;
    }
  }

  // single item
  &__item {
    position: relative;
    flex: 1;
    background: color(white);

    // set zebra background on mobile
    &:nth-of-type(even) {
      @include media-query(max-width sm) {
        background: color(alabaster);
      }
    }

    // set 1px space
    &:not(:first-of-type) {
      @include media-query(sm) {
        margin-left: rem(1px);
      }
    }

    // default color
    @include on-event('&.is-active') {
      background: color(primary, $colors-brand);
      color: #fff;
      z-index: 1;

      #{$b}__link {
        color: color(white);
      }
    }

    // set fake border
    &::after {
      @include media-query(min-width sm) {
        content: '';
        position: absolute;
        right: rem(-1px);
        top: rem(9px);
        bottom: rem(9px);
        width: 1px;
        background: color(black-haze);
        z-index: 1;
      }
    }

    // set small color item
    &::before {
      content: '';
      position: absolute;
      display: none;
      left: 0;
      top: 0;
      width: rem(15px);
      height: 100%;
      background-color: color(primary, $colors-brand);

      @include media-range(phone) {
        display: block;
      }
    }

    &.is-active {
      @include media-range(phone) {
        display: none;
      }

      @include themify($themes: $colors-variant) {
        background-color: theme(primary);
      }
    }

    &--green {
      &::before {
        background-color: color-theme((t-green, primary));
      }

      @include on-event() {
        background: color-theme((t-green, primary));
      }
    }

    &--purple {
      &::before {
        background-color: color-theme((t-purple, primary));
      }

      @include on-event() {
        background: color-theme((t-purple, primary));
      }
    }

    &--orange {
      &::before {
        background-color: color-theme((t-orange, primary));
      }

      @include on-event() {
        background: color-theme((t-orange, primary));
      }
    }

    &--marine {
      &::before {
        background-color: color-theme((t-marine, primary));
      }

      @include on-event() {
        background: color-theme((t-marine, primary));
      }
    }

    &--yellow {
      &::before {
        background-color: color-theme((t-yellow, primary));
      }

      @include on-event() {
        background: color-theme((t-yellow, primary));
      }
    }

    &--pink {
      @include on-event() {
        &::before {
          background-color: color-theme((t-pink, primary));
        }

        background: color-theme((t-pink primary));
      }
    }
  }

  // single link
  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: rem(12px) rem(30px);
    color: currentColor;
  }
}
