// Imgages
// ======
.u-img {
  &-circle {
    width: rem(115px);
    height: rem(115px);
    border-radius: 50%;
    border: 3px solid #007fa3;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  }
}

.images-rounded {
  .image img {
    width: rem(115px);
    height: rem(115px);
    border-radius: 50%;
    border: 3px solid #007fa3;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  }
}
