// Filters popup
// ===========
// Basic search with filter (on readers page)
.c-filters-popup {
  $b: &;

  padding: 0 rem(30px) 0 0;
  background: rgba(0, 0, 0, .9);

  @include media-range(phone) {
    padding: 0;
  }

  h2 {
    color: color(white);

    @include media-range(phone) {
      font-size: 30px;
      max-width: 75%;
    }
  }

  .c-modal {
    max-width: map-get($susy-fixed, container);
    background: transparent;

    @include media-query(lg) {
      padding: 0;
    }

    @include media-range(phone) {
      min-height: 100vh;
      padding: em(20px) em(14px);
    }

    &__content {
      @include media-query(sm) {
        padding-top: rem(35px);
        padding-bottom: rem(35px);
      }
    }

    &__close {
      top: 0;
      right: rem(20px);

      @include media-range(phone) {
        top: rem(20px);
        right: rem(10px);
        background: transparent;
        border: none;
      }

      .o-close {
        @include media-range(phone) {
          width: em(25px);
          height: em(25px);
        }
      }
    }
  }

  &__list {
    margin-top: rem(75px);
    margin-bottom: rem(35px);

    @include media-range(phone) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__item {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @include media-range(phone) {
      margin-bottom: em(10px);
    }
  }

  &__icon {
    @include transition(transform);

    display: none;
    height: 28px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: em(15px);
    margin: auto;
    transform: rotate(90deg);

    @include media-range(phone) {
      display: block;
    }
  }

  &__qty {
    display: none;
    color: color(white);
    border-radius: 50%;
    width: rem(25px);
    height: rem(25px);
    background-color: color(outrageous-orange);
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin-left: rem(10px);

    &:not(.hidden) {
      @include media-range(phone) {
        display: inline-flex;
      }
    }
  }

  &__title {
    position: relative;
    width: 16%;
    padding-top: rem(7px);
    color: color(white);

    @include media-range(phone) {
      width: 100%;
      margin-bottom: 0;
      padding: em(20px) em(15px);
      background-color: color(white);
      color: color(blue-stone);
      font-weight: font-weight((primary, semi-bold));
      font-size: em(18px);
      cursor: pointer;
    }

    &.is-active {
      @include media-range(phone) {
        background-color: color(blue-stone);
        color: color(white);
      }

      #{$b}__icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    width: 84%;
    margin-bottom: rem(20px);

    @include media-range(phone) {
      width: 100%;
      margin-bottom: 0;
      padding-right: rem(15px);
      padding-left: rem(15px);
      background-color: color(catskill-white);
      height: 0;
      opacity: 0;
      overflow: hidden;
      backface-visibility: hidden;
      will-change: height;
      will-change: opacity;

      @include transition();
    }

    &.is-active {
      @include media-range(phone) {
        padding-top: rem(15px);
      }
    }
  }

  &__filter {
    display: flex;
    width: 20%;
    margin-bottom: rem(18px);
    padding-right: rem(10px);
    padding-left: rem(10px);

    @include media-range(phone) {
      width: 50%;
      padding-right: rem(15px);
      padding-left: rem(15px);
    }

    [type=checkbox] {
      position: absolute;
      z-index: -1000;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;

      & + label {
        $font-size-small: map-get($button-font-sizes, small);
        $font-size: map-get($font-size-small, md);

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: em(12px, $font-size) em(19px, $font-size);
        font-size: em($font-size);
        line-height: inherit;
        font-weight: font-weight((primary, semi-bold));
        letter-spacing: 0.075em;
        color: color(blue-stone);
        background: color(white);
        text-align: center;
        transition: all 0.3s;
        z-index: 1;
        cursor: pointer;
        word-break: break-word;

        @include media-range(phone) {
          min-height: rem(110px);
          font-size: em(15px);
        }

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          transition: all 0.3s;
          width: 0%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: color(mango-tango);

          @include media-range(phone) {
            background-color: color(heather);
          }
        }

        // set colors for theme variants
        @include themify() {
          background-color: color(white);
          color: color(blue-stone);
        }

        // set hover and active state
        @include on-event() {
          background: color(white);
          color: color(white);

          &::after {
            width: 100%;
          }
        }
      }

      &:checked + label {
        @include themify() {
          background-color: color(mango-tango);
          color: color(white);

          @include media-range(phone) {
            background-color: color(heather);
          }
        }
      }

      @include on-event() {
        outline: 0;
      }
    }
  }

  &__btns {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 16%;

    @include media-range(phone) {
      padding-left: 0;
    }

    .o-btn {
      $font-size-small: map-get($button-font-sizes, small);
      $font-size: map-get($font-size-small, md);

      width: 100%;
      min-height: 57px;
      min-width: 0;
      font-size: em($font-size);

      &--gray {
        background: color(slate-gray);
      }
    }
  }

  &__btn {
    display: flex;
    width: 20%;
    margin-bottom: rem(18px);
    padding-right: rem(10px);
    padding-left: rem(10px);

    @include media-range(phone) {
      width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: rem(10px);
    }

    &:first-child {
      @include media-range(phone) {
        order: 1;
      }
    }
  }
}
