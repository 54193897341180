// Grid
// ====
// Settings for grid project
//
// stylelint-disable indentation, number-no-trailing-zeros

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$breakpoints: (
        xs: 1px,
        sm: 762px,
        md: 1025px,
        lg: 1200px,
        xl: 1400px
);

$breakpoints-queries: (
        phone: mappy-bp(max-width sm),
        phone-small: mappy-bp(max-width 480px),
        tablet: mappy-bp(sm md),
        medium: mappy-bp(md lg),
        sm-lg: mappy-bp(sm lg),
        large: mappy-bp(lg xl),
        xlarge: mappy-bp(min-width xl),
        mobile: mappy-bp(max-width md),
        desktop: mappy-bp(w md),
        print: mappy-bp(xs, $type: print)
);

// Grid containers
// ---------------
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1300px
) !default;

// Grid columns
// ------------
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12 !default;
$grid-gutter-widths: (
        xs: 14px,
        sm: 20px,
        md: 20px,
        lg: 20px,
        xl: 20px
) !default;

// Susy settings
// -------------
// Fixed grid settings
$susy-fixed: (
        container: 88.000rem,
        //gutters: 1.8750rem/.09375rem,
        column-width: 5.4rem,
        gutter-position: inside-static,
        columns: $grid-columns,
        global-box-sizing: border-box,
  //debug: (image: show-columns, color: rgba(red, 0.5))
);

// Settings map
$susy-settings: (
        default: (
                container: 88.000rem,
                //gutters: 1.8750rem/.09375rem,
                column-width: 5.4rem,
                gutter-position: inside-static,
                columns: $grid-columns,
                global-box-sizing: border-box,
                //debug: (image: show-columns, color: rgba(red, 0.5))
        ),
        xs: (
            column-width: 4rem
        )
);

// Merge default settings with responsive settings
$susy-responsive: (
        xs: map-merge($susy-fixed, map-get($susy-settings, xs))
);

// Set default susy map
$susy: map-get($susy-settings, default);
