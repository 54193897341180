// News list
// =========
.c-list-news {
  padding-bottom: rem(15px);

  &__list {
    display: flex;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    margin-bottom: rem(30px);
    list-style: none;

    @include media-range(phone) {
      margin-bottom: rem(20px);
    }
  }
}
