// Double select with same result
// =====
.c-dblselect {
  .c-arrow {
    width: 200px;
    margin: em(20px) auto 0 auto;
    background: color(blue-stone);
    color: white;

  }
    .c-arrow-point {
      width: 0;
      height: 0;
      border-left: 140px solid transparent;
      border-right: 140px solid transparent;
      border-top: 80px solid color(blue-stone);
      position: relative;
      margin: 0 auto em(10px) auto;
    }
}
