// Login form
// ==========
// on modal
.#{$m}-form {
  &__register {
    margin-top: rem(50px);
  }

  .o-fieldset {
    legend {
      margin-bottom: rem(20px);
    }
  }

  .o-form-row {
    margin-bottom: em(15px);
  }

  .o-label {
    margin-bottom: em(5px);
  }
}
