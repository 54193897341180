// Search filter
// ===========
// Basic search with filter (on readers page)
.c-search-filter {
  $b: &;

  &__bar {
    .c-well {
      &__content {
        @include media-range(phone) {
          display: block;
        }
      }

      &__addon {
        vertical-align: top;

        @include media-range(phone) {
          display: block;
          width: 100%;
        }

        .o-btn--search {
          max-height: rem(43px);
          padding-top: rem(8px);
          padding-bottom: rem(8px);
          font-size: rem(12px);
          line-height: 1.23;
          text-transform: none;
          cursor: pointer;
        }
      }
    }

    .o-form-row {
      position: relative;
      display: table-cell;
      vertical-align: top;
      padding-right: rem(20px);

      @include media-range(phone) {
        display: block;
        margin-bottom: rem(15px);
        padding-right: 0;
      }

      [type=submit] {
        position: absolute;
        margin-top: 0;
        right: rem(36px);
        top: 0;
        bottom: 0;

        .o-icon {
          color: color(abbey);
          width: rem(16px);
          height: rem(16px);
        }
      }
    }
  }

  &__tags {
    $font-size-small: map-get($button-font-sizes, small);
    $font-size: map-get($font-size-small, md);

    margin-bottom: em(13px, $font-size);

    #{$b}__tag {
      position: relative;
      display: inline-block;
      margin-bottom: em(13px, $font-size);
      margin-right: em(6px, $font-size);
      padding: em(12px, $font-size) em(19px, $font-size);
      font-size: em($font-size);
      line-height: inherit;
      font-weight: font-weight((primary, semi-bold));
      letter-spacing: 0.075em;
      color: color(white);
      background: color(slate-gray);
      transition: all 0.3s;
      z-index: 1;

      // set colors for theme variants
      @include themify() {
        background-color: color(slate-gray);
        color: color(white);
      }
    }

    .o-btn {
      @include media-query(xs) {
        width: auto;
        display: inline-block;
        font-size: em($font-size);
        font-weight: font-weight((primary, normal));
        padding: em(12px, $font-size) em(19px, $font-size);
      }
    }
  }
}
