// Login page modal
// ============
.#{$m}-modal {
  background-color: color(prussian-blue);
  color: color(white);

  a {
    color: color(white);
  }

  &__form {
    @include media-query(sm) {
      padding-right: rem(60px);
    }
  }

  .l-section,
  .container {
    padding: 0;
  }
}
