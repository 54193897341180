// Clear buttons styles inside the class
// =====
.u-btn-clear {
  .o-btn {
    max-width: none;
    background: none !important;
    border: none;
    padding: 0;
    margin: 0;
    text-transform: none;
  }
}
