// Alert
// =====
// Alert message
.o-alert {
  padding: 1rem;
  font-size: em(13px);
  border: 1px solid transparent;

  &__close {
    position: relative;
    float: right;
    font-size: em(20px, 13px);
    line-height: 1em;
    top: -1rem;
    right: -1rem;
    padding: 1rem;
    color: inherit;
    cursor: pointer;

    @include on-event() {
      outline: 0;
    }
  }

  &--danger,
  &--s2 {
    $color: map-get($colors-alerts, danger);

    @include alert-variant(theme-color-level($color, -10), theme-color-level($color, -9), theme-color-level($color, 6));
  }

  &--warning,
  &--s1 {
    $color: map-get($colors-alerts, warning);

    @include alert-variant(theme-color-level($color, -10), theme-color-level($color, -9), theme-color-level($color, 6));
  }

  &--success,
  &--s0 {
    $color: map-get($colors-alerts, success);

    @include alert-variant(theme-color-level($color, -10), theme-color-level($color, -9), theme-color-level($color, 6));
  }

  &--list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 5px;
    }
  }
}
