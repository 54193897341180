// Component
// =========
// Product component
.c-component {
  $b: &;

  display: table;

  @include media-range(phone) {
    display: flex;
    flex-wrap: wrap;
  }

  &.is-expanded {
    margin-bottom: rem(25px);
    padding: rem(45px) 0;
    background: color(white);
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.04);
    clear: both;

    @include media-range(phone) {
      padding: rem(20px) rem(15px);
    }

    #{$b}__img {
      @include media-range(phone) {
        padding-left: rem(5px);
      }
    }
  }

  &__img {
    display: table-cell;
    width: 1%;
    padding: 0 em(45px);
    vertical-align: top;
    white-space: nowrap;

    @include media-range(phone) {
      flex: 0 0 auto;
      width: auto;
      padding: 0 em(20px);
    }

    img {
      max-width: rem(160px);

      @include media-range(phone) {
        max-width: rem(75px);
      }
    }
  }

  &__desc {
    vertical-align: top;

    @include media-range(phone) {
      flex: 1;
    }
  }

  &__title {
    margin-bottom: em(10px);
    font-size: em(20px);
    font-weight: font-weight((primary, semi-bold));

    @include media-range(phone) {
      margin-bottom: em(5px);
      font-size: em(18px);
    }
  }

  &__bar {
    width: rem(33px);

    @include media-range(phone) {
      width: rem(28px);
    }

    img {
      width: 100%;
    }
  }

  &__code {
    display: inline-block;
    vertical-align: middle;
  }

  &__price {
    margin-top: em(25px);

    @include media-range(phone) {
      margin-top: em(10px);
    }

    p {
      margin: 0;
    }

    .value {
      font-size: em(24px);
      font-weight: font-weight((primary, semi-bold));
      color: color(blue-stone);

      @include media-range(phone) {
        font-size: em(17px);
      }
    }
  }

  &__cta {
    margin-top: em(20px);

    @include media-range(phone) {
      width: 100%;
    }

    .o-btn {
      margin-bottom: em(10px);
    }

    .o-link {
      display: block;
      margin-bottom: em(10px);
      font-size: em(13px);
    }
  }

  &__base {
    @include media-query(sm) {
      flex-basis: rem(195px);
    }
  }

  &__more {
    display: none;
    flex: 1;

    .is-expanded & {
      display: block;
    }
  }

  &__info {
    span {
      display: block;
    }
  }

  &__level {
    color: #448ccb;
  }

  &__status {
    color: color(fun-green);

    &--unavailable {
      color: color(monza);
    }
  }

  &__links {
    margin-top: rem(20px);
  }

  &__toggle {
    display: block;
    margin-top: rem(30px);

    @include media-range(phone) {
      margin-top: rem(5px);
      text-align: right;
    }
  }
}
