// Product category
// ============
// Wrapper of product list
.#{$m}-category {

  // title + category panel
  //&__header {}

  // title + search
  // @extend components/_header.scss
  &__title {
    margin-bottom: rem(30px);

    .c-header {
      &__addon {
        @include media-range(phone) {
          margin-top: rem(25px);
        }
      }
    }
  }

  // search form in header
  &__search {
    position: relative;

    button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: em(40px);
    }

    // @extend object/_input.scss
    .o-input {
      padding-right: em(40px);

      @include media-query(sm) {
        min-width: rem(420px);
      }

      @include media-range(phone) {
        width: 100%;
      }
    }
  }

  // color panel
  // @extend components/_well.scss
  // @extend components/_header.scss
  // @extend object/_select.scss
  &__subtitle {
    color: color(white);

    @include media-range(phone) {
      margin-left: - gutter(map-get($susy-responsive, xs)) * 2;
      margin-right: - gutter(map-get($susy-responsive, xs)) * 2;
    }

    @include media-range(medium) {
      padding-left: rem(45px);
      padding-right: rem(45px);
    }

    img {
      display: inline-block;
      max-height: rem(50px);
      width: auto;

      & + * {
        display: inline-block;
        margin-left: rem(30px);
        vertical-align: middle;
      }
    }

    .o-select {
      @include media-query(sm) {
        width: rem(330px);
      }
    }

    .c-header {
      @include media-range(phone) {
        &,
        &__addon {
          display: block;
          margin-top: rem(45px);
        }
      }
    }
  }

  &__title,
  &__subtitle {
    .c-header__addon {
      @include media-range(phone) {
        display: block;
        width: auto;
      }
    }
  }

  .c-gallery {
    .c-thumb__caption {
      color: color(blue-stone);
    }
  }
}
