// Table
// =====
// Core table styles
table,
.table {
  display: table;
  width: 100%;
  max-width: 100%;
  margin: 1.5rem 0;
}

thead {
  &.text-left {
    th {
      text-align: left;
    }
  }
}

th {
  padding: em(10px);
  background: color(primary, $colors-brand);
  color: color(white);
  text-align: center;
  font-weight: normal;

  @include themify() {
    background: theme(primary);
  }
}

td,
.table-td {
  display: table-cell;
  padding: em(22px) em(15px);
  background: color(athens-gray);
}

tr,
.table-tr {
  display: table-row;
  border-top: 1px solid color(white);

  &:nth-of-type(odd) {
    td {
      background: #e9eaeb;
    }
  }

  &.transparent {
    border-top: none;

    td {
      background: none;
    }
  }
}
