// Tags
// ====
// Simple tags list
.c-tags {
  text-transform: uppercase;
  font-size: em(12px);
  font-weight: font-weight((primary, semi-bold));
  word-spacing: em(8px);
  color: rgba(color(abbey), .33);
}
