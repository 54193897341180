// Slick slider
// ========
// Simple slider
// @vue-component slider.vue

// Import source file
@import 'slick.scss';

.slick-list {
  @include media-range(phone) {
    margin-left: - gutter(map-get($susy-responsive, xs));
    margin-right: - gutter(map-get($susy-responsive, xs));
  }
}

.slick-slide {
  @include media-range(phone) {
    margin-left: gutter(map-get($susy-responsive, xs));
    margin-right: gutter(map-get($susy-responsive, xs));
  }
}
