// Text
// =====
// Typography utilities
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.flex-center {
  justify-content: center;
}

.text-bigger {
  font-size: em(18px);
}

.text-nowrap {
  white-space: nowrap;
}
