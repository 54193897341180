// 404 Page
// ====================
.m-404 {
  &__content {
    @include media-query(sm) {
      display: table-cell;
      vertical-align: middle;
    }

    @include media-range(phone) {
      padding-top: rem(40px);
    }
  }

  .c-header {
    &__addon {
      width: auto;
      text-align: right;

      @include media-query(sm) {
        padding-right: 5%;
      }

      @include media-range(phone) {
        display: block;
      }

      img {
        @include media-query(sm) {
          height: 65vh;
          max-height: rem(900px);
        }
      }
    }
  }

  .o-btn {
    margin-top: rem(15px);
  }
}
