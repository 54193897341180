// Form
// ==========
// Inputs, submits etc
//
// stylelint-disable indentation

// Typography
// -------------
$input-font-sizes: (
        base: 15px
);

$input-line-heights: (
        base: 21px
);

$input-paddings: (
        base: (
                horizontal: 20px,
                vertical: 10px
        ),
        large: (
                horizontal: 25px,
                vertical: 15px
        )
);

$select-paddings: (
        base: (
                horizontal: 30px,
                vertical: 10px
        ),
        small: (
                horizontal: 15px,
                vertical: 10px
        )
);
