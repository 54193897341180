// Main navigation
// ===============
.c-nav-main {
  $ctx: 20px;
  $b: &;

  position: relative;
  font-size: em($ctx);
  font-family: font-family(secondary);
  background: color(white);

  @include media-range(tablet) {
    font-size: 1em;
  }

  // main list
  &__list {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0 em(60px, $ctx);

    @include media-range(tablet) {
      padding: 0;
    }
  }

  // single position
  &__item {
    flex-grow: 1;
    color: color(black);

    // hover/active state
    &:active,
    &:focus,
    &:hover,
    &.active-dropdown,
    &.is-active {
      #{$b}__link {
        color: currentColor;

        &::after {
          opacity: 1;
          transform: translateY(-4px);
        }
      }
    }

    // show dropdown
    &:hover,
    &:focus,
    &:active,
    &.active-dropdown {
      .c-nav-dropdown {
        opacity: 1;
        transition: none;

        &__list, &__separator {
          opacity: 1;
        }
      }
    }
  }

  // single link element
  &__link {
    position: relative;
    display: flex;
    width: 100%;
    padding: em(20px, $ctx) em(8px, $ctx);
    min-height: em(104px, $ctx);
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: currentColor;
    z-index: z-index(nav-link);

    @include media-range(tablet) {
      padding-left: em(5px, $ctx);
      padding-right: em(5px, $ctx);
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 4px;
      background: color(primary, $colors-brand);
      content: '';
      opacity: 0;
      transition: opacity $animation-time $animation-function, transform $animation-time $animation-function;
      transform: translateY(6px);
    }
  }

  // @extend .c-nav-dropdown styles
  &__dropdown {
    padding-left: em(60px, $ctx);
    padding-right: em(60px, $ctx);
    font-size: em(18px, $ctx);
    pointer-events: none;
    opacity: 0;
    transition: all .8s $animation-function;
  }

  &__home {
    flex-grow: 0.2;
  }

}
