// Alert
// ======
// Alert color variant
// ----------------
// - $background      : <string> background color
// - $border          : <string> border color
// - $color           : <string> text color
@mixin alert-variant($background, $border, $color) {
  color: $color;
  background-color: $background;
  border-color: $border;

  hr {
    border-top-color: darken($border, 5%);
  }

  .alert-link {
    color: darken($color, 10%);
  }
}
