// Socials
// ==========
// Social media icons
.c-socials {
  &__list {
    @include list-unstyle;
  }

  &__item {
    display: inline-block;
    margin-right: rem(30px);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link {
    color: currentColor;
  }

  .o-icon {
    width: auto;
    height: auto;
    max-width: rem(60px);
    max-height: rem(30px);
  }
}
