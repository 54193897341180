// Pagination
// ==========
.c-pagination {
  $b: &;
  $background: map-get($colors-form, input-bg);
  $paddings-base: map-get($input-paddings, base);

  @include list-unstyle;

  text-align: center;

  &__item {
    display: inline-block;
    margin: 0 rem(5px) rem(10px) 0;
    color: color(primary, $colors-brand);

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &--more {
      margin-left: rem(10px);
      margin-right: rem(10px);
    }

    &--prev {
      margin-right: rem(20px);
    }

    &--next {
      margin-left: rem(20px);
    }

    &:hover,
    &.is-active {
      #{$b}__link {
        color: color(white);
        background: color(primary, $colors-brand);

        @include themify() {
          background: theme(primary);
        }
      }
    }

    &:not(.is-active):not(#{$b}__item--prev):not(#{$b}__item--next) {
      @include media-range(phone-small) {
        display: none;
      }
    }
  }

  &__link {
    display: block;
    padding: em(map-get($paddings-base, vertical)) em(map-get($paddings-base, horizontal));
    border: 1px solid #e5e5e5;
    color: currentColor;
    background: $background;

    // set colors for theme variants
    @include themify() {
      color: theme(primary);
    }

    @include media-range(phone) {
      padding: em(7px) em(15px);
    }
  }
}
