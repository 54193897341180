// Scaffolding
// ===========
// Style for base html elements

html,
body {
  //height: 100%;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

html {
  //height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

object,
embed {
  @include media-range(phone) {
    max-width: 100%;
  }
}
