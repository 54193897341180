// Tags news
// =========
// List of tags for eg single news
.c-list-tags {
  @include list-unstyle;

  &__item {
    display: inline-block;
    padding: em(5px);
    margin-right: em(8px);
    border: 1px solid #a9d5e0;
    color: #acacac;
    line-height: 1;
  }
}
