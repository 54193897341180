// Logo component
// ==============
.c-logo {
  padding: rem(25px);

  @include media-range(tablet) {
    padding: rem(16px, sm);
  }

  @include media-range(phone) {
    padding: rem(14px) 0;
  }

  img {
    height: rem(50px);
    width: rem(160px);

    @include media-range(mobile) {
      width: auto;
    }

    @include media-range(tablet) {
      height: rem(34px, sm);
    }

    @include media-range(phone) {
      min-width: rem(106px);
      height: rem(32px);
    }
  }
}
