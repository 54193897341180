// Video
// =========
.c-video {
  $b: &;

  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  & object,
  & embed,
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
