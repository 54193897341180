// Course selector
// ===========
.calculate-quiz-button {
  margin-bottom: 25px;
}
.c-tabs-course-selector a.c-tabs__link {
  text-align: left;
}
.course-selector-container .container {
  padding-left: 0;
  padding-right: 0;
}
