.lib-loader-rotate {
  > div {
    @include balls();
    @include global-animation();

    border: 2px solid $primary-color;
    border-bottom-color: transparent;
    height: 25px;
    width: 25px;
    background: transparent !important;
    display: inline-block;
    animation: rotate 0.75s 0s linear infinite;
  }
}
