// Links
// =====
.o-link {
  $b: &; // set context block
  $ctx: 14px; // set font-size context
  display: inline-block;
  text-transform: uppercase;
  font-size: em($ctx);
  font-weight: font-weight((primary, semi-bold));
  letter-spacing: 0.075em;

  @include themify() {
    color: theme(primary);
  }

  @include on-event('&.is-active, a:hover &') {
    color: color(secondary, $colors-brand);

    #{$b}__icon {
      background-color: color(secondary, $colors-brand);
      border-color: color(secondary, $colors-brand);
      color: color(white);

      .o-icon {
        color: currentColor;

        &.o-icon-arrow-download {
          animation: toBottomFromTop 0.3s forwards;
        }

        &.o-icon-arrow {
          animation: toRightFromLeft 0.3s forwards;
        }
      }
    }
  }

  @include media-range(phone) {
    font-size: 12px;
  }

  &__icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: auto auto .2em em(5px, $ctx);
    width: em(25px, $ctx);
    height: em(25px, $ctx);
    border-radius: 50%;
    border: 2px solid color(primary, $colors-brand);

    @include themify() {
      border-color: theme(primary);
    }

    @include media-range(mobile) {
      border-width: 1px;
      width: 18px;
      height: 18px;
    }

    .o-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: em(13px, $ctx);
      height: em(13px, $ctx);
      color: color(primary, $colors-brand);

      @include themify() {
        color: theme(primary);
      }

      @include media-range(mobile) {
        width: 50%;
        height: 50%;
      }
    }

    &--left {
      margin-left: 0;
      margin-right: em(5px, $ctx);
    }

    // remove border around and round shape
    &--noround {
      border: none;
      border-radius: 0;

      .o-icon {
        @include media-query(sm) {
          width: em(24px, $ctx);
          height: em(24px, $ctx);
        }

        @include media-range(phone) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &--white {
    color: color(white);

    &:hover {
      color: color(hover, $colors-link);
    }
  }

  &.show-active {
    color: color(white);

    &:hover, &:active {
      color: color(mango-tango);

      &.u-color-orange {
        color: color(white) !important;
      }
    }
  }
}
