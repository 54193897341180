// Single news item
// ===========
.m-news-single {
  &__links {
    margin: rem(40px) 0;
  }

  &__related {
    margin: rem(60px) 0;
  }

  .c-gallery {
    margin-top: rem(50px);
    margin-bottom: rem(50px);

    &__item {
      img {
        width: 100%;
      }
    }
  }

  .c-list-links {
    max-width: rem(760px);
  }
}
