.#{$m}-list {
  .c-list-checkbox {
    margin-top: rem(40px);
    margin-bottom: rem(40px);

    @include media-range(phone) {
      margin-top: rem(20px);
      margin-bottom: rem(20px);
    }
  }
}
