// Banner home
// ===========
.c-banner-home {
  position: relative;
  width: 100%;

  @include media-query(md) {
    height: 65vh;
    min-height: rem(380px);
    margin-bottom: rem(50px);
  }

  img {
    width: 100%;
  }

  &,
  &__cover {
    background-size: cover;
    background-position: center;

    @include media-range(mobile) {
      background: none !important; // disable inline styles
    }
  }

  &__cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__switcher {
    @include media-query(md) {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: rem(-30px);
    }
  }
}
