// Loader
// ======
// Simple css spinner
.c-spinner {
  @include transition(background);

  position: relative;

  // @extend lib/loaders-css.scss
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &--big {
    > div {
      width: 100px;
      height: 100px;
      border-width: 5px;
    }
  }

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(color(black), 0.5);
    z-index: z-index(backdrop) + 1;
  }
}
