// Conference popup
// ================
.#{$m}-popup {
  &__title {
    color: color(blue-stone);

    @include media-range(phone) {
      font-size: em(18px);
    }
  }

  &__subtitle {
    font-size: em(20px);

    @include media-range(phone) {
      font-size: em(15px);
    }
  }

  .c-list-checkbox__list {
    margin-top: rem(20px);
    margin-bottom: rem(60px);

    li {
      margin-bottom: rem(20px);
    }
  }
}
