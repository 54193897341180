// Advantage list
// =================
.c-advantages {
  $ctx: 18px; // set font-size context
  position: relative;
  font-size: em($ctx);

  @include media-range(phone) {
    font-size: 9px;
  }

  @include media-range(tablet) {
    font-size: 10px;
  }

  &::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: em(130px, $ctx);
    left: 0;

    @include media-range(tablet) {
      height: em(117px);
    }
  }

  &::before {
    top: 0;
    background-color: color(porcelain);

    @include media-range(phone) {
      background-color: transparent;
    }
  }

  &__list {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    @include media-range(tablet) {
      max-width: 465px;
    }
  }

  &__item {
    margin: 0 auto;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  &__img {
    position: relative;
    display: block;
    vertical-align: middle;
    margin: 0 auto em(5px, $ctx);
    width: em(157px, $ctx);
    height: em(157px, $ctx);
    border-radius: 50%;
    background-color: color(white);

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      max-height: em(90px, $ctx);
      max-width: em(90px, $ctx);
    }
  }

  &__desc {
    color: color(blue-stone);

    @include media-range(phone) {
      line-height: 1.26;
    }
  }
}
