// Typography
// ==========
// Core fonts setiings
html {
  // apply correctly font-size
  // on each breakpoint by typi
  font-family: font-family(primary);
  font-feature-settings: 'kern' 1;
  text-rendering: optimizeLegibility;
  color: color(cod-gray);
}

// init typi font sizes on html element
@include typi-init;

// Headings
// --------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 0.83em;
}

// According to styleguide secondary font set
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-family: font-family(secondary);
  font-weight: font-weight((secondary, normal));
}

h1,
.h1 {
  @include typi(h1, $rem: false, $typi-breakpoint: mappy-bp);
}

h2,
.h2 {
  @include typi(h2, $rem: false);
}

h3,
.h3 {
  @include typi(h3, $rem: false);
}

// According to styleguide primary font set
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-family: font-family(primary);
  font-weight: font-weight((primary, normal));
}

h4,
.h4 {
  @include typi(h4, $rem: false);
}

h5,
.h5 {
  @include typi(h5, $rem: false);
}

h6,
.h6 {
  @include typi(h6, $rem: false);
}

// Paragraphs
// ----------
p {
  margin: 0 0 1em;
}

// Small
// -----
small {
  font-size: em(13px);
}

// Links
// -----
a {
  text-decoration: none;
  color: color(normal, $colors-link);

  &:hover,
  &:active,
  &:focus {
    color: color(hover, $colors-link);
  }
}

// Blockquote
// ----------
blockquote {
  $ctx: 24px; // set font size context
  position: relative;
  display: inline-block;
  margin: 1em 0;
  padding: 1em 2em;
  font-size: em($ctx);
  font-family: font-family(secondary);
  font-weight: font-weight((secondary, normal));
  font-style: italic;

  &::before,
  &::after {
    position: absolute;
    content: "\201C";
    width: 0.5em;
    height: 0.5em;
    font-size: em(100px, $ctx);
    line-height: 1;
    color: color(primary, $colors-brand);

    @include themify() {
      color: theme(primary);
    }
  }

  &::before {
    left: -0.1em;
    top: -0.1em;
  }

  &::after {
    content: "\201D";
    bottom: 0;
    right: 0.1em;
  }

  cite {
    display: block;
    margin-top: em(20px, $ctx);
    font-size: em(15px, $ctx);
    font-family: font-family(primary);
    font-style: normal;
  }
}

// Lists
// -----
ul,
ol,
dl,
dt {
  margin-top: 0;
}
