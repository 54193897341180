// Slider
// ==========
// Simple slider based on slick
// @vue-component slider.vue
// @css-plugin plugins/_slider.scss
// @extend components/_hero-img.scss
.c-slider {
  $b: &;

  position: relative;

  @include media-query(sm) {
    //overflow: hidden;
    //height: rem(400px);
  }

  .c-hero-img {
    @include media-query(sm) {
      display: flex;
      align-items: center;
    }

    &__content {
      @include media-query(sm) {
        position: absolute;
        top: 0;
        padding: em(40px) em(90px);
      }
    }
  }

  // single slide
  &__item {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  // slider thumb on mobile
  &__thumb {
    width: 100%;

    img {
      width: 100%;
    }

    @include media-range(phone) {
      margin-bottom: em(15px);
    }
  }

  // pagination list
  &__dots {
    @include list-unstyle;

    position: absolute;
    left: 0;
    right: 0;
    top: rem(30px);
    margin: auto;
    text-align: center;

    li {
      display: inline-block;
      margin-right: rem(5px);
    }
  }

  // single pagination dot
  &__dot {
    display: inline-block;
    width: rem(12px);
    height: rem(12px);
    background: color(white);
    border: 1px solid #c5c7c7;
    border-radius: 50%;

    .slick-active & {
      background: color(primary, $colors-brand);

      @include themify() {
        background: theme(primary);
      }
    }
  }

  &__btn {
    position: absolute;
    display: block;
    width: rem(32px);
    height: rem(32px);
    background: color(white);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
    z-index: 1;
    cursor: pointer;

    @include transition();

    &--left {
      left: rem(-10px);
    }

    &--right {
      right: rem(-10px);
    }
  }

  &--content {
    #{$b}__btn {
      @include media-query(sm) {
        top: 0;
        bottom: 0;
        margin: auto;
        background: transparent;
        box-shadow: none;
        width: em(35px);
        height: em(35px);

        .o-icon {
          height: 100%;
        }
      }
    }

    #{$b}__item {
      @include media-query(sm) {
        padding-left: em(45px);
        padding-right: em(45px);
      }
    }

    .slick-list {
      @include media-query(sm) {
        margin-left: em(45px);
        margin-right: em(45px);
      }
    }

    .ce-gallery {
      @include media-query(sm) {
        width: 45%;
      }
    }
  }
}
