// List materials
// ==============
// List - Bank of materials
.c-expand {
  $b: &;

  cursor: pointer;

  @include list-unstyle();

  &__icon {
    display: table-cell;
    width: rem(25px);
    height: rem(25px);
    vertical-align: middle;
    color: color(blue-stone);
    cursor: pointer;

    @include media-range(phone) {
      margin-bottom: rem(5px);
    }

    .o-icon {
      width: 100%;
      height: 100%;
      color: color(blue-stone);

      @include transition();
    }
  }

  &__content {
    @include transition();

    height: 0;
    opacity: 0;
    overflow: hidden;
    backface-visibility: hidden;
    will-change: height;
    will-change: opacity;
  }

  &__item {
    margin-bottom: rem(10px);
  }

  &__header {
    display: table;
  }

  &__title {
    display: table-cell;
    vertical-align: middle;
    padding-left: rem(10px);
  }

  &[aria-expanded=true],
  [aria-expanded=true] {
    #{$b}__icon {
      .o-icon {
        transform: rotate(135deg);
      }
    }
  }
}
