// Gallery
// =======
// Simple grid gallery
.c-gallery {
  @include susy-clearfix;
  @include grid-make-default-row();

  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-bottom: rem(25px);

    .c-thumb__img {
      margin-left: auto;
      margin-right: auto;
    }

    figure {
      width: 100%;
      max-width: 100%;
    }

    iframe {
      max-width: 100%;
    }
  }

  &__cols-5 {
    @include media-query(sm) {
      width: 20%;
    }

    .c-thumb {
      $b: &;

      &--link {
        @include media-query(md) {
          font-size: em(14px, sm);
        }

        #{$b}__caption {
          @include media-query(md) {
            margin-top: rem(6px);
            min-height: em(38px, 12px);
          }
        }
      }
    }
  }
}
