$ctx: map-get($input-font-sizes, base);
$paddings-base: map-get($input-paddings, base);
$paddings-large: map-get($input-paddings, large);
$line-height-base: map-get($input-line-heights, base);
$color: map-get($colors-form, input-color);
$border-color: map-get($colors-form, input-border);
$background: map-get($colors-form, input-bg);

.gallery-item-remove-margin-bottom {
  .c-gallery__item {
    margin-bottom: 0;
  }
}

.remove-padding-bottom {
  padding-bottom: 0 !important;
}

.subcontainers-no-horizontal-padding {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.half-half-container {
  .container div .c-grid .row .col-md-6:first-of-type {
    padding-left: 0 !important;

    @media screen and (max-width: 1025px) and (min-width: 762px) {
      padding-left: 1.3rem !important;
      padding-right: 0 !important;
    }
  }

  .container .m-user-home {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .l-section {
      padding-left: 1.3rem;

      @media screen and (max-width: 1025px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .m-user-data {
        padding-left: 2rem !important;
        padding-right: 0 !important;
      }
    }
  }
}

.md-pr-0 {
  padding-right: 0 !important;
  @include media-range(phone) {
    padding-right: .675rem !important;
  }
}

.md-mr-0 {
  margin-right: 0 !important;
  @include media-range(phone) {
    margin-right: .675rem !important;
  }
}

.md-mr-0 {
  margin-right: 0 !important;
  @include media-range(phone) {
    margin-right: .675rem !important;
  }
}

.xs-pl-1 {
  @include media-range(phone) {
    padding-left: .73333rem !important;
  }
}

.xs-ml-1 {
  @include media-range(phone) {
    margin-left: .73333rem !important;
  }
}

.tabs-flex-wrap-items {
  .c-tabs {
    &__list {
      flex-wrap: wrap;
    }

    &__item {
      flex: unset;
      padding: 0.675rem;
    }
  }
}

.image-embed-item {
  &.desktop {
    @include media-query(max-width sm) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @include media-query(max-width sm) {
      margin: auto;
      display: block;
    }
  }
}

.half-input {
  width: 50%;
  @include media-range(phone) {
    width: 100%;
  }
}

.textarea-like {
  padding: em(map-get($paddings-base, vertical), $ctx) em(map-get($paddings-base, horizontal), $ctx);
  -moz-appearance: textfield-multiline !important;
  -webkit-appearance: textarea !important;
  border: solid $border-color !important;
  border-width: 1px 0 0 1px;
  min-height: 70px;
  background-color: $background;
  color: $color;
  overflow: auto !important;
  resize: both !important;
  width: 100%;
}

.mb-0 {
  margin-right: 0 !important;
}


.cloak-loading {
  .cloak-content {
    display: none;
  }

  .cloak-text {
    display: block;
    text-align: center;
  }
}

.cloak-text {
  display: none;
}

.overflow-mobile {
  @include media-range(phone) {
    overflow-x: auto !important;
  }
}
