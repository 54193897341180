// Fieldset
// ========
// Group of form elements
.o-fieldset {
  margin: 0;
  padding: 0;
  border: 0;

  & + & {
    margin-top: rem(40px);

    @include media-range(phone) {
      margin-top: rem(25px);
    }
  }

  legend,
  &__legend {
    @include typi(h2, $rem: false);

    margin-bottom: rem(40px);
    font-family: font-family(secondary);
    font-weight: font-weight((secondary, normal));
  }
}
