// Animate
// ==========
// Default settings for animation
//
// sass-lint:disable indentation

// Animate settings
// ------------
$animation-function: ease-in-out;
$animation-time: .3s;
