// List-recent
// =========
// Recent releases list
.c-block-recent {
  &__list {
    list-style: none;
    padding: 0;
  }

  // @extend components/_header.scss
  .c-header {
    margin-bottom: rem(50px);

    &__addon {
      @include media-range(phone) {
        vertical-align: bottom;
      }
    }
  }

  // @extend components/_product.scss
  .c-product {
    @include media-range(phone) {
      font-size: em(12px);
    }

    &__title,
    &__subtitle {
      color: color(abbey);
    }

    &__title {
      margin-bottom: 0;
    }
  }
}
