// Fade
// =====
// Vue simple fades animation
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-enter-active,
.fadeIn,
.fade-leave-active,
.fadeOut {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.fade-enter-active,
.fadeIn {
  animation-name: fadeIn;
}

.fade-leave-active,
.fadeOut {
  animation-name: fadeOut;
}
