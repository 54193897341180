// Dropdown
// ========
// Main navigation dropdown
.c-nav-dropdown {
  $b: &;

  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  padding: rem(40px);
  background: color(mercury);
  z-index: z-index(dropdown);

  &__list {
    @include list-unstyle;
    @include transition(opacity, 0.5s);

    column-count: 3;
    opacity: 0;

    > * {
      display: inline-block;
      width: 100%;
    }
  }

  &__item {
    margin-bottom: 1em;

    &.has-submenu {
      display: block;
      width: 25%;
      padding: 0 rem(10px);
    }

    & > ul {
      padding-left: 0;
      font-family: font-family(primary);
      font-size: rem(16px);

      @include media-range(mobile) {
        font-size: rem(13px);
      }

      & > li {
        &.is-active {
          font-weight: bold;
        }
      }
    }
  }

  &__separator {
    @include transition(opacity, 0.5s);

    padding-bottom: 10px;
    margin: 0 0 10px 0;
    color: color(normal, $colors-link);
    border-bottom: 1px solid color(normal, $colors-link);

    opacity: 0;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &--sub {
    #{$b}__list {
      column-count: unset;
      display: flex;
    }

    #{$b}__link {
      border-bottom: 3px solid color(blue-stone);
      display: inline-block;
      margin-bottom: rem(10px);
    }
  }
}
