// Aside navigation
// ================
.c-nav-aside {
  $b: &; // set context for block

  &__list {
    @include list-unstyle;

    overflow: hidden;
  }

  &__item {
    background: color(white);
    color: color(mine-shaft);
    font-size: em(13px);
  }

  &__link {
    display: table;
    width: 100%;
    height: rem(59px);
    color: currentColor;

    // set hover actions
    @include on-event('&.is-active') {
      color: currentColor;

      img {
        opacity: 1;
      }

      #{$b}__ico {
        background-color: #c7d000;

        &::before {
          content: '';
          right: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 8px 7px 0;
          border-color: transparent color(porcelain) transparent transparent;

          @include transform-center($horizontal: false);
        }
      }

      .o-icon {
        color: rgba(color(black), 0.66);
      }

      #{$b}__label {
        background-color: color(porcelain);
        border-color: color(black-haze);
        border-bottom-color: color(iron);
      }
    }
  }

  &__ico {
    position: relative;
    display: table-cell;
    width: rem(69px);
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: color(bitter-lemon);

    img {
      opacity: 0.32;
      width: auto;
      height: auto;
      max-height: rem(32px);
      max-width: rem(24px);
    }

    .o-icon {
      width: rem(24px);
      height: rem(24px);
      color: rgba(color(black), 0.32);
    }

    &--gray {
      background: color(porcelain);
    }

    &--pistachio {
      background: color(pistachio);
    }

    &--yellow {
      background: color(bitter-lemon);
    }

    &--marine {
      background: color(mountain-meadow);
    }

    &--purple {
      background: color(flirt);
    }
  }

  &__label {
    display: table-cell;
    padding: rem(5px) rem(5px) rem(5px) rem(20px);
    border: 1px solid transparent;
    border-left: 0;
    vertical-align: middle;
  }
}
