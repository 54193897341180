// CMS table
// ======
// Clear and style tables from old cms
.u-table-cms {
  table-layout: fixed; // force wrap content to cell

  tr {
    border: 0;

    &:nth-of-type(odd) {
      td {
        background: none;
      }
    }
  }

  td {
    padding: 0;
    background: none;

    & + td {
      padding-left: 1.5em;
    }

    // force breaking columns on mobile
    @include media-range(phone) {
      display: table-row;
    }
  }
}

.u-table,
.u-table-conf,
.u-table-cert {
  overflow-x: auto;
  margin-bottom: rem(30px);

  @include media-range(phone) {
    margin-bottom: rem(20px);
    margin-right: rem(-15px);
  }

  table,
  .table {
    margin-bottom: 0;

    @include media-range(phone) {
      white-space: nowrap;
    }
  }

  .table-bordered {
    td,
    th {
      border: 1px solid #f4f6f7;
    }
  }

  td,
  .table-td {
    padding: em(22px) em(15px);
    background: #eef0f1;
  }

  .table-td-small {
    width: 1%;
  }

  tr,
  .table-tr {
    border-top: 1px solid #f4f6f7;

    &:nth-of-type(odd) {
      td,
      .table-td {
        background: #e9eaeb;
      }
    }
  }

  .thead-dark {
    tr {
      td,
      th,
      .table-th,
      .table-tr {
        background-color: darken(#e9eaeb, 5%);
        color: black;
      }
    }
  }

  .o-btn {
    &--small {
      $font-size-small: map-get($button-font-sizes, small);
      $font-size: map-get($font-size-small, md);

      @include media-query(xs) {
        font-size: em($font-size);
        font-weight: font-weight((primary, normal));
        padding: em(12px, $font-size) em(28px, $font-size);
      }
    }

    // when btn is a link with icon
    &--icon {
      // @extend objects/_links.scss
      position: relative;

      @include media-query(xs) {
        padding-right: 3.8em;
      }

      .o-link__icon {
        @include media-query(xs) {
          position: absolute;
          right: 1em;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}

.u-table-cert {
  margin-right: 0;

  table,
  .table {
    margin-top: 0;
  }
}
