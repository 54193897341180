// Map component
// ==============
.c-map {
  height: rem(530px);
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #d3d6d7;

  @include media-range(phone) {
    height: rem(300px);
  }

  &__info {
    padding-top: rem(5px);
  }

  &__canvas {
    width: 100%;
    height: 100%;
  }
}
