// Layout b2s-lp
// ============
// Custom layout with fixed aside

.l-b2s-lp {
  position: relative;
  min-height: rem(350px);

  &__main {
    .container {
      position: relative; // for background elements purposes
    }
  }

  &__cms {
    position: relative;

    .header-img {
      padding: 0;

      .c-gallery__item {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .schools {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .school {
      flex-grow: 1;
      position: relative;
      color: white;

      p {
        margin: 0 0 0.7em;
      }

      a {
        color: white;
      }

      .divider::after {
        background-color: inherit;
        bottom: 5%;
        content: '';
        display: block;
        height: 60%;
        right: 0;
        position: absolute;
        transform: translate(-50%, 0);
        width: 3px;
      }

      &:last-of-type {
        .divider::after {
          width: 0;
        }
      }

      @include media-range(sm-lg) {
        padding-bottom: 1em;
        &:nth-of-type(3n) {
          .divider::after {
            width: 0;
          }
        }
      }

      @include media-range(phone) {
        padding-bottom: 1em;
        &:nth-of-type(2n) {
          .divider::after {
            width: 0;
          }
        }
      }
    }

    .col-text {
      padding-right: 1rem;
      padding-left: 1rem;
      padding-top: 0.5rem;
      font-size: 13px;

      @include media-range(phone) {
        font-size: 14px;
      }

      @include media-range(sm-lg) {
        font-size: 15px;
      }

      ul {
        padding-left: 1.5rem;

        li {
          ul {
            padding-left: 1rem;
          }
        }
      }
    }

    .col-img {
      .c-gallery {
        margin: 0;
        .c-gallery__item {
          padding: 0;
          margin: 0;
        }
      }

      img {
        width: 100%;
        margin: auto;

        @include media-range(phone) {
          width: 80%;
        }

        @include media-range(sm-lg) {
          width: 85%;
        }
      }
    }

    .img-full-width {
      img {
        width: 100%;
      }
    }

  }

  &__aside {
    // make fake container to keep proportions
    @include grid-make-default-container();

    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }

  .aside-main {
    position: sticky;
    top: rem(10px);
    margin-bottom: rem(20px);
    float: right;
    min-height: 100px;
    width: rem($l-aside);
    background: gray;
    pointer-events: all;
  }
}
