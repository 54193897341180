// Nav mobile
// ==========
.c-nav-mobile {
  $bar-height: rem(60px); // set height of bottom bar
  $b: &; // set block context
  $ctx-link: 21px; // set font size context for main list

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

  // pull up menu on active state
  &.is-active {
    height: 100%;
    z-index: z-index(nav-mobile);

    #{$b}__cover {
      transform: translateY(0);
    }
  }

  // set links centered
  &.is-center {
    #{$b}__cover {
      text-align: center;
      font-family: font-family(secondary);
    }
  }

  // bar at bottom of the page
  &__bar {
    @include list-unstyle;

    //position: relative;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    height: $bar-height;
    background: color(white);
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;

    .o-icon {
      height: rem(18px);
      width: rem(18px);
    }
  }

  // single bar button
  &__btn {
    flex: 1;
    height: 100%;
    text-align: center;
  }

  // common element for bar and list
  &__position {
    display: block;
    height: 100%;
    padding: rem(12px) 0;
  }

  // label of button
  &__name {
    display: block;
    font-size: em(10px);
  }

  // main list
  &__list {
    @include list-unstyle;

    clear: both;
  }

  // single item link
  &__item {
    &.is-active {
      color: map-get($colors-link, 'hover');
    }
  }

  // main menu layer
  &__cover {
    @include transition();

    //top: 0;
    height: 100%;
    width: 100%;
    padding: rem(45px) rem(15px) $bar-height rem(15px);
    background: color(porcelain);
    overflow: auto;
    transform: translateY(100%);
    color: color(black);
    pointer-events: auto;

    .o-burger {
      height: rem(40px);
      width: rem(25px);
      float: right;
    }
  }

  &__link {
    display: flex;
    margin-bottom: em(15px, $ctx-link);
    font-size: em($ctx-link);
    background: color(white);
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
    align-items: stretch;
    color: currentColor;

    @include on-event('&.is-active') {
      img {
        opacity: 1;
      }
    }
  }

  &__ico {
    position: relative;
    width: em(60px, $ctx-link);
    background: color(bitter-lemon);

    img {
      opacity: 0.32;
      width: auto;
      height: auto;
      max-height: rem(32px);
      max-width: rem(24px);

      @include transform-center();
    }
  }

  &__label {
    flex: 1;
    padding: em(14px, $ctx-link) em(14px, $ctx-link) em(14px, $ctx-link) em(24px, $ctx-link);
  }
}
