// Radio button
// ====
// Basic radio button form
.o-radio {
  position: absolute;
  z-index: -1000;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  & + label {
    display: inline-block;
    position: relative;
    margin-right: rem(11px);
    width: em(16px);
    height: em(16px);
    border: 1px solid rgba(0, 0, 0, 0.35);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }

  &:checked + label::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: em(7px);
    height: em(7px);
    margin: auto;
    display: block;
    border-radius: 50%;
    background-color: color(blue-stone);
    transform: translate(-50%, -50%);
  }

  @include on-event() {
    outline: 0;
  }
}
