// Ribbon level
// ============
// Level of book
.c-ribbon-level {
  position: relative;
  display: inline-block;
  min-width: em(110px);
  margin-left: em(28px);
  padding: em(3px) em(10px) em(3px) 0;
  font-weight: font-weight((primary, semi-bold));
  text-transform: uppercase;
  text-align: center;
  color: color(white);
  background-color: color(deep-cerulean);
  z-index: 0;
  box-shadow: 1px 1px 5px 0 rgba(#3b3b3b, 0.34);

  @include media-range(tablet) {
    font-size: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    z-index: -1;
    border-style: solid;
    border-color: transparent transparent #0080a3;
    border-width: 0 em(28px) em(30px) em(28px);
    margin-left: em(-28px);

    @include media-range(tablet) {
      font-size: 10px;
      margin-left: -20px;
    }
  }

  &--small {
    $ctx: 10px;

    height: em(20px, $ctx);
    min-width: em(90px, $ctx);
    padding: em(3px, $ctx) em(10px, $ctx) em(3px, $ctx) em(5px, $ctx);
    font-size: em($ctx);
    line-height: em(14px, $ctx);

    &::before {
      border-width: 0 em(15px, $ctx) em(20px, $ctx) em(15px, $ctx);
      margin-left: em(-15px, $ctx);
      font-size: inherit;
    }
  }
}
