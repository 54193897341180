// Panel
// ======
// Panel title + content
.c-panel {
  $border-radius: 8px;
  $b: &;

  border-radius: $border-radius;
  border: 1px solid color(iron);
  background-color: color(white);

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    padding: em(5px);
    min-height: em(40px, 13px);
    font-weight: font-weight((primary, semi-bold));
    background: color(geyser);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    color: color(rolling-stone);
    text-align: center;
  }

  &__content {
    padding: em(10px) em(15px);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background: color(white);
    word-break: break-word;
  }

  // set content and title side by side
  &--xs-inline {
    @include media-range(phone) {
      display: table;

      > div {
        display: table-cell;
        border-radius: 0;
        vertical-align: middle;
        width: 50%;

        &:first-of-type {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }

        &:last-of-type {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }

      &__title {
        width: 1%;
        white-space: nowrap;
      }
    }
  }
}
