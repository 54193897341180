// Search results
// ========
.m-search-results {
  .c-search-form {
    display: none;
  }

  .c-list-checkbox__list {
    & > li {
      cursor: pointer;
    }
  }

  .c-badge-circle {
    text-transform: uppercase;
  }
}
