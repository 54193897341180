// Responsive
// ==========
// Utilities based on media queries
.hidden {
  display: none;
}

.visible-xs {
  display: none !important;

  @include media-range(phone) {
    display: block !important;
  }
}

.hidden-xs {
  @include media-range(phone) {
    display: none !important;
  }
}

.visible-sm {
  display: none;

  @include media-range(tablet) {
    display: block !important;
  }
}

.hidden-sm {
  @include media-range(tablet) {
    display: none !important;
  }
}

.hidden-md {
  @include media-range(medium) {
    display: none !important;
  }
}

.hidden-lg {
  @include media-query(lg) {
    display: none !important;
  }
}

[v-cloak] {
  visibility: hidden;
}
