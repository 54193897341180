// Well
// =====
// Simple padding block
.c-well {
  padding: em(30px);
  background: color(white);

  @include media-range(phone) {
    padding: em(20px) em(15px);
  }

  &--small {
    padding: em(25px);

    @include media-range(phone) {
      padding: em(20px) em(15px);
    }
  }

  &--big {
    @include media-query(sm) {
      padding: em(35px) em(85px);
    }
  }

  &__content {
    display: table;
    width: 100%;
  }

  &__addon {
    width: 1%;
    white-space: nowrap;
    display: table-cell;
    vertical-align: middle;
  }
}
