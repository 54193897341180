// Links list
// ==========
// Simple unstyled list
.c-list-links {
  $b: &; // set block context

  &,
  &__list {
    @include list-unstyle;
  }

  &__item {
    margin-bottom: rem(15px);
  }

  &__link {
    color: currentColor;
  }

  &__desc {
    display: inline-block;
    vertical-align: top;
  }

  &__img {
    display: inline-block;
    vertical-align: top;
    padding-right: rem(10px);

    @include media-range(phone) {
      display: block;
    }
  }

  &__size {
    padding-left: rem(10px);
  }

  &--inline {
    #{$b}__item {
      display: inline-block;
      margin-bottom: 0;
      margin-right: rem(15px);
    }
  }

  &--cols {
    @include media-query(sm) {
      column-count: 3;
    }
  }

  &--space-bottom {
    margin-bottom: rem(25px);

    #{$b}__item {
      margin-bottom: rem(15px);
    }
  }
}
