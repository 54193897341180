// Grid
// =====
// Main header for whole project

// make default container
.container {
  @include grid-make-default-container();
}

// make default row
.row {
  @include grid-make-default-row();

  &--eq-height {
    display: flex;
  }
}

// Set default settings for cols
@include grid-make-default-columns();

// Set columns on small breakpoint
// .col-xs-x
@include media-query(xs) {
  @include grid-make-columns('xs', 'width');
  @include grid-make-columns('xs', 'gutter');
  @include grid-make-float-columns('xs');
}

// Set columns on small breakpoint
// .col-sm-x
@include media-query(sm) {
  @include grid-make-columns('sm', 'width');
  @include grid-make-columns('sm', 'gutter');
  @include grid-make-float-columns('sm');
}

// Set columns on medium breakpoint
// .col-md-x
@include media-query(md) {
  @include grid-make-columns('md', 'width');
  @include grid-make-columns('md', 'gutter');
  @include grid-make-float-columns('md');
}

// Set columns on min-width large breakpoint
// .col-lg-x
@include media-query(lg) {
  @include grid-make-columns('lg', 'width');
  @include grid-make-columns('lg', 'gutter');
  @include grid-make-float-columns('lg');
}

// make clearfix
.clearfix {
  @include susy-clearfix;
}

.col-center {
  margin: 0 auto;
  float: none;
}
