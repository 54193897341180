// Typography
// ==========
// Font sizes and families
//
// stylelint-disable indentation

// Font Families
// -------------
$font-fallbacks: (
        sans-serif: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        serif: 'Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif'
);

$font-families: (
        primary: ("Open Sans", #{(map-get($font-fallbacks, 'sans-serif'))}),
        secondary: ("Playfair Display", map-get($font-fallbacks, 'serif'))
) !default;

// Base typography
// ---------------
$font-sizes: (
        base: 15px,
        xs:   15px,
        sm:   13px,
        md:   15px,
        lg:   15px,
        xl:   15px
);

$line-heights: (
        base: 1.6,
        xs:   1.6,
        sm:   1.6,
        md:   1.6,
        lg:   1.6,
        xl:   1.6
);

$font-weights: (
        primary: (
                normal:    400,
                semi-bold: 600
        ),
        secondary: (
                normal:    400,
                bold:      700
        )
) !default;

// Headings
// --------
$headings-sizes: (
        h1: (
                null: (30px, 39/30), // default is xs
                sm:   (1.769em, 28/22), // for context 13px
                md:   (36px, 48/36),
        ),
        h2: (
                null: (22px, 29/22), // default is xs
                sm:   (1.538em, 22/15), // for context 13px
                md:   (24px, 36/24)
        ),
        h3: (
                null: (18px, 24/16), // default is xs
                sm:   (1.385em, 18/12), // for context 13px
                md:   (20px, 30/20)
        ),
        h4: (
                null: (15px, 24/15)
        ),
        h5: (
                null: (13px, 24/13)
        ),
        h6: (
                null: (11px, 24/11)
        )
);

$subheadings-sizes: (
        h1-sub: (
                null: (20px, 32/20)
        ),
        h2-sub: (
                null: (18px, 31/18)
        ),
        h3-sub: (
                null: (16px, 28/16)
        ),
        h4-sub: (
                null: (13px, 22/13)
        ),
        h5-sub: (
                null: (13px, 24/13)
        ),
        h6-sub: (
                null: (11px, 24/11)
        )
);

// Typi library set
// ----------------
$typi: (
        base: (
                null: (
                        map-get($font-sizes, 'base'),
                        map-get($line-heights, 'base')
                ),
                xs:   (
                        map-get($font-sizes, 'xs'),
                        map-get($line-heights, 'xs')
                ),
                sm:   (
                        map-get($font-sizes, 'sm')
                        map-get($line-heights, 'sm')
                ),
                md:   (
                        map-get($font-sizes, 'md')
                        map-get($line-heights, 'md')
                ),
                lg:   (
                        map-get($font-sizes, 'lg'),
                        map-get($line-heights, 'lg')
                ),
                xl:   (
                        map-get($font-sizes, 'xl'),
                        map-get($line-heights, 'xl')
                )
        )
);

$typi: map-merge(map-merge($typi, $headings-sizes), $subheadings-sizes);
