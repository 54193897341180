// Hero simple image
// =================
.c-hero-img {
  $b: &;

  @include media-query(sm) {
    min-height: rem(400px);
    height: 1px; // children have to min-height inherit
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    color: color(white);
  }

  @include media-range(medium) {
    min-height: rem(350px);
  }

  &--blue {
    @include media-query(sm) {
      color: color(deep-cerulean);
    }
  }

  &--lemon {
    @include media-query(sm) {
      color: color(bitter-lemon);
    }
  }

  &--black {
    @include media-query(sm) {
      color: color(black);
    }
  }

  &--mint {
    @include media-query(sm) {
      color: color(blue-stone);
    }
  }

  @include media-range(phone) {
    // show image on mobile
    background: none !important; // disable inline background
  }

  &__content {
    width: 100%;

    p {
      & + .o-btn {
        margin-top: em(15px);
      }
    }

    @include media-query(sm) {
      padding: em(70px) em(90px);
    }

    @include media-range(phone) {
      // set RTE elements to left always on mobile
      > * {
        text-align: left;
      }

      br {
        display: none;
      }
    }
  }
}
