// Space
// ======
// Various paddings / margins / spacing
.u-space {
  &-before--extra-small {
    padding-top: 0 !important;
  }

  &-before--extra-small-sm-up {
    @include media-query(sm) {
      padding-top: 0 !important;
    }
  }

  &-left {
    padding-left: rem(35px) !important;
  }

  &-after--extra-small {
    padding-bottom: 0 !important;
  }

  @each $bp, $size in $space-base {
    // get breakpoint map and apply padding
    @include media-query(#{$bp}) {
      @each $space in map-fetch($space-base, $bp) {
        &-before--#{nth($space, 1)} {
          padding-top: rem(nth($space, 2)) !important;
        }

        &-after--#{nth($space, 1)} {
          padding-bottom: rem(nth($space, 2)) !important;
        }
      }
    }
  }

  &-mobile--none {
    @include media-range(phone) {
      padding-bottom: 0 !important;
    }
  }

  &-no-space {
    padding: 0;
  }
}
