// News blocks
// ===========
.c-block-news {
  max-width: 100%;

  &__teaser {
    @include media-query(sm) {
      float: left;
      max-width: rem(340px);
      margin-right: rem(70px);
      padding-top: rem(60px);
    }

    @include media-range(phone) {
      margin-bottom: rem(20px);
    }
  }

  &__list {
    @include list-unstyle;

    display: flex;

    @include media-range(phone) {
      margin: 0 rem(-10px);
    }
  }

  &__item {
    display: flex;
    width: auto;
    min-width: rem(330px);

    @include media-range(phone) {
      min-width: rem(265px);
      padding-left: rem(10px);
      padding-right: rem(10px);
    }
  }

  .c-card {
    //min-height: 460px;
    //height: 100%;
  }
}
