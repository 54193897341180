// Badge circle
// ============
.c-badge-circle {
  $ctx: 12px; // set font-size context

  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(70px);
  height: rem(70px);
  border-radius: 100%;
  font-size: rem($ctx);
  font-family: font-family(primary);
  font-weight: font-weight((primary, semi-bold));
  text-align: center;
  color: color(white);
  background: color(deep-cerulean);

  &--small {
    font-size: rem(10px);
  }
}
