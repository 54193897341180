// Product
// =======
// Single product item
.c-product {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-bottom: em(30px);

  @include media-query(sm) {
    max-width: rem(245px);
  }

  &__img {
    position: relative;
    display: flex;
    min-height: rem(200px);
    margin-bottom: rem(40px);
    text-align: center;
    align-items: center;

    img {
      margin: 0 auto;
      width: auto;
      max-height: rem(200px);
      box-shadow: 0 11px 20px -4px rgba(0, 0, 0, 0.34);

      @include media-query(sm) {
        max-width: rem(150px);
      }
    }

    &-badge {
      position: absolute;
      left: 0;
      top: rem(30px);
      text-transform: uppercase;

      * {
        margin-bottom: rem(8px);
      }
    }
  }

  &__desc {
    position: relative;
    display: block;
    padding-right: rem(30px);

    .o-link {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__title {
    margin-bottom: em(5px);
    color: color(deep-cerulean);
    text-transform: uppercase;
    font-weight: font-weight((primary, semi-bold));
  }

  &__subtitle {
    margin-bottom: 0;
  }

  &__title-small {
    margin-bottom: 0;
    color: color(abbey);
    font-size: rem(15px);
    text-transform: uppercase;
    font-weight: font-weight((primary, semi-bold));
  }

  &__subtitle-small {
    font-size: rem(15px);
    color: color(abbey);
    margin-bottom: 0;
  }

  &__authors {
    color: #a4a4a4;
  }

  &__badges {
    min-height: rem(30px);
    margin-bottom: rem(30px);

    &-top {
      position: relative;
      margin-bottom: rem(-15px);
      z-index: 2;

      @include media-range(phone) {
        margin-bottom: rem(15px);
      }

      .c-ribbon-level--small {
        font-size: em(12px);
      }
    }

    &-bottom {
      margin-bottom: rem(15px);
      margin-top: rem(-25px);
      text-align: right;

      img,
      .c-badge-level {
        margin-right: rem(10px);
      }
    }

    // @extend components/_badge-level.scss
    img,
    .c-badge-level {
      display: inline-block;
      vertical-align: middle;
      margin-right: rem(5px);
      height: rem(37px);
      min-width: rem(37px);
    }

    &-item {
      display: inline-block;

      @include media-range(phone) {
        display: table;
        margin-bottom: rem(15px);
      }
    }

    &-img {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 12px;

      @include media-range(phone) {
        display: table-cell;
        width: 40px;
        vertical-align: middle;
        text-align: left;
      }

      img {
        @include media-range(phone) {
          margin-bottom: 0;
        }
      }

      .c-badge-level {
        @include media-range(phone) {
          margin: 0;
        }
      }
    }

    &-desc {
      display: block;
      width: 100%;
      text-align: center;

      @include media-range(phone) {
        display: table-cell;
        width: auto;
        padding-left: rem(15px);
        vertical-align: middle;
        text-align: left;
      }
    }
  }

  .o-link {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
