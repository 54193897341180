.#{$m}-user {
  .c-accordion {
    &__header {
      padding: rem(25px) rem(30px);
      background-color: color(blue-stone);
      color: color(white);

      @include media-range(phone) {
        padding: rem(15px);
      }

      .o-icon,
      .c-accordion__title,
      .c-accordion__addon {
        color: color(white);
      }
    }

    &__title {
      font-family: font-family(secondary);
      font-size: 24px;

      @include media-range(phone) {
        font-size: 15px;
      }
    }
  }

  .c-list-conferences {
    &__list {
      margin-top: rem(10px);
    }

    &__item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
