// News list
// =========
.c-list-checkbox {
  &__title {
    color: color(blue-stone);
    font-weight: bold;
    font-size: em(15px);
  }

  &__btn {
    margin-top: rem(35px);
    text-align: right;

    @include media-range(phone) {
      margin-top: 0;
    }

    &.text-center {
      text-align: center;
    }

    .o-btn {
      text-align: center;
    }
  }

  &__list {
    @include list-unstyle;

    @include media-range(phone) {
      margin-bottom: rem(10px);
    }

    > * {
      display: inline-block;
      width: 100%;
    }

    li {
      margin-bottom: rem(6px);
      max-width: 100%;

      @include media-range(phone) {
        margin-bottom: rem(1px);
      }
    }

    &[data-cols='2'] {
      @include media-query(sm) {
        column-count: 2;
      }
    }

    &[data-cols='3'] {
      @include media-query(sm) {
        column-count: 3;
      }
    }

    &[data-cols='4'] {
      @include media-query(sm) {
        column-count: 4;
      }
    }
  }
}
