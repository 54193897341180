// Header
// ======
// Single product page header
.#{$m}-head {
  $b: &;

  // set div ordering
  &__img {
    @include media-query(sm) {
      float: left;
    }
  }

  &__desc {
    @include media-query(sm) {
      float: right;
    }
  }

  &__lvls {
    margin-bottom: 0;

    .c-tabs {
      &__list {
        flex-wrap: wrap;
        margin-right: rem(-7px);
        margin-left: rem(-7px);
      }

      &__item {
        position: relative;
        width: 25%;
        max-width: 25%;
        margin-bottom: gutter(map-get($susy-responsive, xs)) * 2;
        padding-right: rem(7px);
        padding-left: rem(7px);

        @include media-range(phone) {
          width: 50%;
          max-width: 50%;
        }

        &:hover,
        &.is-open {
          .c-tabs__link {
            @include media-query(lg) {
              background-color: color(white);
            }
          }
        }
      }

      &__link {
        display: flex;
        min-height: rem(43px);
        margin: 0;
        align-items: center;
        justify-content: center;
        padding-top: rem(10px);
        padding-bottom: rem(10px);
        cursor: pointer;
        text-transform: uppercase;
        font-weight: font-weight((primary, semi-bold));
        line-height: 1;
        font-size: rem(10px);
        min-width: auto;

        &[aria-expanded=true] {
          background: color(blue-stone);
          color: color(white);

          &::after {
            background: color(blue-stone);
            width: 100%;
          }
        }
      }
    }
  }
  //}

  // cover
  &__cover {
    padding: rem(25px);

    @include media-range(phone) {
      padding: rem(20px) rem(40px);
    }

    img {
      box-shadow: 1px 9px 20px 0 rgba(0, 0, 0, 0.28);
    }

    .c-badge-circle {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      text-transform: uppercase;

      @include media-query(xs) {
        bottom: 0;
        top: 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .8);
        transform: rotate(-25deg);
      }

      @include media-query(sm) {
        font-size: rem(18px);
        width: rem(100px);
        height: rem(100px);
        top: auto;
        bottom: rem(10px);
        right: auto;
        left: rem(15px);
      }
    }
  }

  &__title {
    position: relative;
    display: table;
    margin-bottom: rem(10px);
    font-size: rem(28px);
  }

  &__category {
    &,
    a {
      font-size: em(16px);
      font-weight: font-weight((primary, semi-bold));
      text-transform: uppercase;
      color: color(deep-cerulean);

      @include media-range(phone) {
        margin-top: rem(20px);
      }
    }
  }

  &__men {
    margin-top: rem(20px);
    margin-bottom: rem(20px);

    // @extend components/_panel.scss
    .c-panel {
      width: 100%;
      margin-bottom: em(10px);
      line-height: 1.3;

      @include media-query(sm) {
        display: inline-block;
        max-width: em(160px);
        width: 30%;
        margin-right: 3%;
        margin-left: -0.1em;
      }

      &__title {
        @include media-range(phone) {
          br {
            display: none;
          }
        }
      }

      &__content {
        text-align: center;
        font-weight: font-weight((primary, bold));

        p {
          margin-bottom: 0;
        }
      }

      // give darken background for next elements based on base background color
      & + .c-panel {
        .c-panel__title {
          border-color: darken(color(geyser), 4%);
          background-color: darken(color(geyser), 4%);
        }

        & + .c-panel {
          .c-panel__title {
            border-color: darken(color(geyser), 4%);
            background-color: darken(color(geyser), 9%);
          }
        }
      }
    }
  }

  // @extend components/_nav-main.scss
  &__nav {
    $ctx: 15px;

    margin-top: rem(15px);
    font-size: em($ctx);

    .c-nav-main__link {
      padding: em(12px, $ctx);
    }
  }

  // @extend components/_product.scss / &__badges
  .c-product__badges {
    margin-top: rem(30px);

    @include media-range(phone) {
      top: 100%;
    }

    &:not(.c-product__badges-sign) {
      @include media-range(phone) {
        position: absolute;
        display: block;
        right: 0;
        z-index: 1;
      }
    }

    img,
    .c-badge-level {
      width: em(37px);
      height: em(37px);

      @include media-range(phone) {
        display: block;
        margin-bottom: em(15px);
      }

      span {
        word-wrap: break-word;
        line-height: 1;
      }
    }

    &-img {
      img,
      .c-badge-level {
        @include media-range(phone) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__tags {
    margin-left: rem(-5px);
    margin-right: rem(-5px);
    margin-bottom: rem(22px);

    #{$b}__tag {
      display: inline-block;
      margin-left: rem(5px);
      margin-right: rem(5px);
      margin-bottom: rem(5px);
      padding-left: rem(12px);
      padding-right: rem(12px);
      text-transform: uppercase;
      background-color: color(slate-gray);
      color: color(white);
    }
  }

  &__barcode {
    margin-top: rem(11px);
    margin-bottom: rem(39px);

    &-img {
      display: inline-block;
      margin-right: rem(5px);
      vertical-align: text-bottom;

      img {
        width: rem(33px);
        height: rem(17px);
      }
    }
  }

  &--price {
    #{$b}__tags {
      margin-top: rem(15px);
    }

    #{$b}__subtitle {
      margin-bottom: 0;
    }

    #{$b}__barcode {
      margin-top: 0;
      margin-bottom: rem(20px);
    }

    .c-component__price {
      margin-top: 0;
      margin-bottom: em(15px);
    }

    .c-product__badges {
      margin-top: 0;
    }
  }
}
