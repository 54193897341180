// Overlay
// =======
.c-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .66);
  z-index: z-index(backdrop);
  opacity: 1;
  overflow: hidden;
  overflow-y: scroll;

  &--content-center {
    $gutter: grid-get-gutter(xs);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: rem(40px) $gutter;
  }
}
