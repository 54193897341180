// Hero simple background image
// =================
.c-hero-bg {
  $b: &;

  padding: rem(137px) rem(43px) 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: left top;

  @include media-range(phone) {
    padding: 0;
    margin-left: - rem(15px);
    margin-right: - rem(15px);
  }

  &__content {
    padding: rem(37px) rem(45px);
    background-color: color(white);

    @include media-range(phone) {
      padding: rem(15px) rem(15px) rem(25px);
    }

    h1 {
      margin-bottom: rem(15px);
      color: color(blue-stone);

      @include media-range(phone) {
        margin-bottom: rem(20px);
      }
    }

    p {
      color: black;

      @include media-range(phone) {
        line-height: 1.26;
      }
    }
  }

  &__info {
    display: flex;
    width: 100%;
    max-width: 667px;
    margin: rem(35px) auto rem(43px);

    @include media-range(phone) {
      display: block;
      max-width: 100%;
      margin-top: rem(20px);
      margin-bottom: rem(25px);
    }

    &--bigger {
      max-width: 800px;
    }

    &-wrap {
      display: table-cell;
      padding-left: 45px;

      @include media-query(sm) {
        flex-grow: 1;
        flex-basis: 0;
      }

      @include media-range(phone) {
        display: block;
        width: 100%;
        margin-bottom: rem(10px);
        padding-left: rem(10px);
        line-height: 1.26;
      }
    }

    &-icon {
      display: table-cell;
      width: 1%;

      .o-icon {
        width: rem(20px);
        height: rem(20px);
      }
    }

    &-text {
      display: table-cell;
      padding-left: rem(20px);

      @include media-range(phone) {
        padding-left: rem(25px);
      }
    }
  }

  &__btns {
    text-align: center;

    @include media-query(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .o-btn {
      margin-left: rem(25px);
      margin-right: rem(25px);

      @include media-range(phone) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: rem(10px);
      }

      @include media-range(tablet) {
        margin-left: rem(20px);
        margin-right: rem(20px);
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;

        @include media-range(phone) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__btns-alt {
    text-align: center;

    @include media-query(sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .o-btn {
      margin-right: rem(25px);
      margin-left: rem(25px);

      @include media-query(sm) {
        flex: 0 0 28%;
      }

      @include media-range(phone) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: rem(10px);
      }

      @include media-range(tablet) {
        margin-left: rem(20px);
        margin-right: rem(20px);
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;

        @include media-range(phone) {
          margin-bottom: 0;
        }
      }
    }
  }
}
