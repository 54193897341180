// Men component
// ==============
.c-men {
  &__list {
    margin-top: rem(30px);
  }

  &__desc {
    display: block;
    width: 70%;
    float: left;

    @include media-range(phone) {
      width: 100%;
    }
  }

  &__number {
    display: block;
    width: 30%;
    float: left;
    padding-top: rem(8px);

    @include media-range(phone) {
      width: 100%;
    }

    .c-panel {
      max-width: 100%;
      width: 154px;
      margin-left: auto;

      @include media-range(phone) {
        width: 100%;
        margin-left: 0;
      }

      &__title {
        font-size: rem(13px);
      }

      &__content {
        padding-top: em(5px);
        padding-bottom: em(6px);
        font-size: rem(15px);
      }
    }
  }

  &__links {
    display: block;
    width: 100%;

    &::before,
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  &__item {
    position: relative;
    display: block;
    margin-bottom: rem(28px);
    padding-top: rem(26px);
    padding-bottom: rem(16px);
    padding-right: rem(67px);
    box-shadow: 0 0 21px rgba(0, 0, 0, 0.06);

    @include media-range(phone) {
      padding-bottom: rem(25px);
      padding-top: rem(25px);
      padding-right: 15px;
    }

    &:hover {
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    }

    .c-well__addon {
      text-align: center;

      @include media-range(phone) {
        display: block;
        width: auto;
        margin-left: rem(-15px);
        margin-right: rem(-15px);
      }

      @include media-range(medium) {
        display: block;
        width: 100%;
      }
    }

    .o-btn {
      display: block;
      padding-right: rem(22px);
      padding-left: rem(22px);

      @include media-range(medium) {
        display: inline-block;
        width: 50%;
      }

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &__addon {
    display: table-cell;
    width: rem(215px);
    max-width: 100%;
    padding-top: rem(12px);
    vertical-align: top;
    text-align: center;

    @include media-range(phone) {
      display: block;
      margin-bottom: rem(5px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__img {
    position: relative;
    display: inline-block;
    height: rem(160px);

    &-badge {
      position: absolute;
      left: rem(-55px);
      top: rem(35px);

      &.school-list-view {
        bottom: 3rem;
        left: -0.5rem;
        top: inherit;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    margin-bottom: rem(2px);

    @include media-range(phone) {
      margin-bottom: rem(15px);
      font-size: rem(18px);
    }

    &,
    a:not(:hover) {
      color: color(blue-stone);
    }
  }

  &__author {
    font-size: rem(15px);
    margin-bottom: em(7px);
  }

  &__lvl {
    font-size: rem(16px);
    font-weight: 600;
    margin-bottom: em(11px);
  }

  & &__content {
    display: block;
    width: 100%;
    padding-top: rem(13px);
    padding-left: rem(23px);

    @include media-range(phone) {
      display: block;
      padding-right: 0;
      padding-left: 0;
    }

    @include media-range(medium) {
      display: block;
      padding-right: 0;
      margin-bottom: rem(13px);
    }

    .c-list-links {
      margin-bottom: em(5px);

      .c-list-links__item {
        margin-right: 0;

        &:not(:last-child) {
          margin-right: rem(45px);

          @include media-range(phone) {
            display: block;
            margin-right: 0;
          }
        }
      }
    }
  }

  .c-well__content {
    @include media-range(phone) {
      display: block;
    }
  }

  .c-list-links {
    margin-top: rem(33px);
    margin-bottom: rem(38px);
  }

  p {
    @include media-query(md) {
      font-size: rem(18px);
    }
  }
}
