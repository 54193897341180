// Secitons
// ========
.l-section {
  // get medium size from md array
  $space-md: map-get(map-fetch($space-base, md), medium);

  padding: rem($space-md) 0;

  @include media-range(tablet) {
    // get medium size from sm array
    $space-sm: map-get(map-fetch($space-base, sm), medium);

    padding-top: rem($space-sm, sm);
    padding-bottom: rem($space-sm, sm);
  }

  @include media-range(phone) {
    // get medium size from xs array
    $space-xs: map-get(map-fetch($space-base, xs), medium);

    padding-top: rem($space-xs);
    padding-bottom: rem($space-xs);
  }

  // nested section
  .l-section {
    padding: 3rem;

    @include media-range(mobile) {
      padding: 1.5rem;
    }
  }

  // overflow if content is pull to browser edge
  &--pull {
    overflow: hidden;
  }

  // give scroll
  &--scrollable {
    overflow: auto;
  }

  // start content from
  &--pre-container {
    padding-left: calc(50% - 88rem / 2);

    .container {
      max-width: 100%;
    }
  }

  // color definitions
  &--white {
    background: color(white);
  }

  &--gray {
    background: color(porcelain);
  }

  &--mint {
    background: color(skeptic);
  }

  &--pistachio {
    background: color(pistachio);
  }

  &--bitter-lemon {
    background: color(bitter-lemon);
  }

  &--blue {
    background: color(prussian-blue);
    color: color(white);

    a:not(.o-btn--white) a:not(.show-active) {
      color: color(white);
    }
  }

  &--user-edit {
    &:nth-of-type(even) {
      background: color(white);
    }
  }

  &.audio-container {
    .c-accordion__wrapper .ce-gallery {
      color: #fff;
      > div {
        width: 100%;

        audio {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &--old-consents {
    textarea {
      width: 100%;
    }
  }

  &.no-padding {
    padding: 0;
  }
}
