/* COOKIE
-------------------------------------------------------------- */
.c-cookie {
  position: relative;
  margin-right: rem(5px);
  background-color: color(white);
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, .1);
  min-height: rem(125px);
  padding: rem(25px) rem(120px) rem(25px) rem(65px);

  @include media-query(max-width md) {
    padding: rem(25px) rem(60px) rem(25px) rem(30px);
  }

  @include media-range(mobile) {
    padding: rem(25px);
  }

  @include media-range(phone) {
    margin-right: 0;
    padding: rem(15px);
  }

  &__wrapper {
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    bottom: rem(25px);
    z-index: map-get($z-index, cookie);
  }

  &__text {
    p {
      margin-bottom: 0;
      margin-top: rem(5px);

      @include media-range(phone) {
        font-size: rem(13px);
        text-align: justify;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &__close {
    position: absolute;
    top: rem(-15px);
    right: rem(-15px);
    width: rem(30px);
    height: rem(30px);
    border-radius: 50%;
    border: 2px solid color(white);
    background: color(primary, $colors-brand);
    color: color(white);
    cursor: pointer;

    @include themify() {
      background-color: theme(primary);
    }

    .o-close {
      @include transform-center;
    }
  }
}
