// Home
// ====
// Main account page
.#{$m}-home {

  // @extend modules/user/_data.scss
  .#{$m}-data {
    margin-bottom: rem(40px);

    @include media-range(phone) {
      margin-bottom: rem(25px);
    }

    // left column
    &:not(.u-space-no-space):nth-of-type(odd) {
      @include media-query(sm) {
        padding-right: rem(45px);
        clear: both;
      }
    }

    // right column
    &:not(.u-space-no-space):nth-of-type(even) {
      @include media-query(sm) {
        padding-left: rem(45px);
      }
    }

    &__list {
      @include media-query(md) {
        padding: 0 rem(55px);
      }
    }
  }

  .log-out {
    display: none;
    font-size: rem(24px);

    @include media-range(phone) {
      display: inline-block;
    }
  }
}
