// Close
// =====
// Simple X button
// https://codepen.io/ndeniche/pen/ljbDL
.o-close {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  overflow: hidden;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: currentColor;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &--big {
    transform: scale(3);
  }

  &--hairline {
    &::before,
    &::after {
      height: 1px;
    }
  }

  &--thick {
    &::before,
    &::after {
      height: 4px;
      margin-top: -2px;
    }
  }

  &--black {
    &::before,
    &::after {
      height: 8px;
      margin-top: -4px;
    }
  }

  &--heavy {
    &::before,
    &::after {
      height: 12px;
      margin-top: -6px;
    }
  }
}
