// Grid
// ====
// Grid mixins
// HTML grid inspired from bootstrap on susy
//
// Approach:
// ---------
// Inspired by
// - https://github.com/kkomelin/susyboot
// - https://zellwk.com/blog/migrating-from-bootstrap-to-susy
// - https://github.com/twbs/bootstrap-sass

$susy-fixed: map-get($susy-settings, default);

// Set background and border to a block in order to debug the grid.
// ----------------------------------------------------------------
@mixin grid-debug-cell() {
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

// Make default container
// ----------------------
@mixin grid-make-default-container() {
  @include container($susy-fixed);

  padding-left: gutter($susy-fixed);
  padding-right: gutter($susy-fixed);

  @include susy-clearfix;

  @include media-query(max-width sm) {
    // bigger paddings to push container from browser edge
    padding-left: gutter(map-get($susy-responsive, xs)) * 2;
    padding-right: gutter(map-get($susy-responsive, xs)) * 2;
  }
}

// Make default row
// ----------------
@mixin grid-make-default-row($debug: false) {
  margin-left: - gutter($susy-fixed);
  margin-right: - gutter($susy-fixed);

  @if $debug == true {
    & > div {
      @include grid-debug-cell;
    }
  }

  @include media-query(max-width sm) {
    margin-left: - gutter(map-get($susy-responsive, xs));
    margin-right: - gutter(map-get($susy-responsive, xs));
  }

  @include susy-clearfix;
}

// Make default cols
// -----------------
// Set default values for all cols
@mixin grid-make-default-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }

  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: - gutter($susy-fixed) * -1;
    padding-right: - gutter($susy-fixed) * -1;
  }
}

// Make floating cols
// ------------------
// Set floating for all cols on specific breakpoint
@mixin grid-make-float-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }

  #{$list} {
    float: left;
  }
}

// Make columns amd their type
// ---------------------------
@mixin grid-make-columns($class, $type, $columns: $grid-columns) {
  @for $i from 0 through $columns {
    @include _grid-calc-column($i, $class, $type);
  }
}

// Return given type for cols
// --------------------------
@mixin _grid-calc-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: get-span-width($index of $grid-columns $susy-fixed);
    }
  }

  @if ($type == gutter) {
    .col-#{$class}-#{$index} {
      padding-left: gutter(map-get($susy-responsive, #{$class}));
      padding-right: gutter(map-get($susy-responsive, #{$class}));
    }
  }

  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }

  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      left: auto;
    }
  }

  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }

  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      right: auto;
    }
  }

  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}

// Pull element to 100% out of container
// -------------------------------------
@mixin grid-pull-out-container() {
  margin-left: - gutter($susy-fixed);
  margin-right: - gutter($susy-fixed);

  @include media-query(max-width sm) {
    margin-left: - gutter(map-get($susy-responsive, xs)) * 2;
    margin-right: - gutter(map-get($susy-responsive, xs)) * 2;
  }
}

@function grid-get-gutter($breakpoint: null) {
  @if ($breakpoint == xs) {
    @return gutter(map-get($susy-responsive, xs)) * 2;
  }

  @else {
    @return gutter($susy-fixed) * 2;
  }
}
