// Modal
// ==============
// Style modal plugin
// @vue-component components/modal/modal.vue
.c-modal {
  position: relative;
  width: 100%;
  max-width: rem(780px);
  margin: auto;
  background-color: white;
  text-align: left;
  box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.57);
  padding: em(50px);

  @include media-range(mobile) {
    padding: em(30px) em(20px);
  }

  &__close {
    position: absolute;
    top: rem(-15px);
    right: rem(-15px);
    width: rem(30px);
    height: rem(30px);
    border-radius: 50%;
    border: 2px solid color(white);
    background: color(primary, $colors-brand);
    color: color(white);
    cursor: pointer;

    @include themify() {
      background-color: theme(primary);
    }

    .o-close {
      @include transform-center;
    }
  }
}
