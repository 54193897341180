// Streaming video
// ==============
.c-stream {
  &__wrapper {
    margin: rem(47px) auto rem(92px) auto;
    max-width: 684px;
  }

  &__video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
