// Search form
// ===========
// Basic search form in header
.c-search-form {
  $b: &; // set block context

  &--expand {
    position: absolute;
    //width: 0;
    max-width: 100%;
    overflow: hidden;
    width: 100%;

    @include transition();
  }

  .o-input {
    width: 100%;
    font-style: italic;
  }

  .o-close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: rem(30px);
    margin: auto;
    width: rem(16px);
    height: rem(16px);
    color: color(abbey);
  }
}
