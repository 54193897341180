// Tag
// ===
// Simple tag badge
.c-tag {
  display: inline-block;
  padding: rem(1px) rem(8px);
  border-radius: 8px;
  font-family: font-family(primary);
  font-size: 1rem;
  background: rgba(color(abbey), .33);
  color: color(white);
}
