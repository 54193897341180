// Payment form
// ===========

.payment-form {
  .row {
    padding-bottom: 1em;
  }

  input.o-input {
    width: 50%;
    @include media-query(max-width sm) {
      width: 100%;
    }
  }

  .select-field.col-sm-4 {
    @include media-query(sm) {
      padding-right: unset;
    }
  }

  .acknowledgement {
    .col-sm-12:first-of-type {
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
}
