// Product components
// ==================
.#{$m}-components {
  // custom breakpoints needed
  $custom-queries: (
    tablet: mappy-bp(sm md),
    tablet-medium: mappy-bp(sm 1290px),
    medium: mappy-bp(md 1290px),
    large: mappy-bp(min-width 1290px)
  );

  @include media-range(phone) {
    margin-left: - gutter(map-get($susy-responsive, xs)) * 2;
    margin-right: - gutter(map-get($susy-responsive, xs)) * 2;
  }

  &__list {
    @include susy-clearfix();
  }

  // @extend components/_tabs.scss
  .c-tabs {
    &__menu,
    &__content {
      @include media-range(phone) {
        padding-left: gutter(map-get($susy-responsive, xs)) * 2;
        padding-right: gutter(map-get($susy-responsive, xs)) * 2;
      }
    }

    &__menu {
      @include media-range(phone) {
        padding-top: rem(15px);
        background-color: color(porcelain);
      }
    }
  }

  // @extend components/_component.scss
  .c-component {
    @include media-query(sm) {
      float: left;
      width: 50%;
      margin: rem(30px) 0;
    }

    @include media-range(phone) {
      margin: rem(20px) 0 rem(30px) 0;
    }

    &:nth-child(2n + 1) {
      clear: both;
    }

    &.is-expanded {
      width: 100%;
      float: none;

      .c-component__desc {
        @include media-range(medium, $custom-queries) {
          padding-right: rem(15px);
        }
      }
    }

    &__img {
      @include media-range(tablet-medium, $custom-queries) {
        padding: 0 1.8rem;

        img {
          max-width: rem(130px);
        }
      }
    }

    &__details {
      @include media-range-or(tablet 'or' large, $custom-queries) {
        display: flex;
        padding-right: rem(30px);
      }
    }

    &__more {
      @include media-range-or(tablet 'or' large, $custom-queries) {
        padding: 0 0 0 rem(30px);
      }
    }
  }
}
