// Progress bar
// ======
.c-progress {
  @include media-range(phone) {
    display: table;
    width: 100%;
    line-height: 1;
  }

  &__bar {
    display: inline-block;
    height: rem(18px);
    width: rem(70px);
    vertical-align: middle;
    background: color(black-squeeze);

    @include media-range(phone) {
      display: table-cell;
      width: rem(100px);
      max-width: rem(100px);
      height: rem(15px);
    }

    span {
      display: block;
      height: 100%;
      background-color: color(pistachio);
    }
  }

  &__text {
    position: relative;

    @include media-range(phone) {
      display: table-cell;
      padding-right: rem(30px);
      white-space: nowrap;
    }

    &::after {
      content: '';
      display: inline-block;
      width: rem(1px);
      height: rem(38px);
      margin-left: rem(15px);
      margin-right: rem(15px);
      vertical-align: middle;
      background-color: #e5e8e9;

      @include media-range(phone) {
        display: none;
      }
    }
  }

  &__qty {
    margin-left: rem(15px);

    @include media-range(phone) {
      display: table-cell;
      padding-left: rem(10px);
    }
  }
}
