// Search test
// ===========
// Basic search form in header
.c-search-test {
  $b: &; // set block context

  &__form {
    position: relative;

    .o-input {
      &::placeholder {
        color: #000;
      }
    }
  }

  & &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: em(15px);
    margin-top: 0;
    cursor: pointer;
  }

  .o-form-row {
    margin-bottom: rem(20px);

    &--space-bottom-0 {
      margin-bottom: 0;
    }
  }
}
