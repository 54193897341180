// grid blocks
// ===========
.l-grid {
  $b: &;

  list-style: none;
  padding: 0;
  margin-top: rem(15px);
  margin-bottom: rem(15px);
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    margin: 0 0 rem(60px) 0;

    @include media-range(phone) {
      margin-bottom: rem(30px);
    }
  }

  &--narrow {
    @include media-query(lg) {
      margin-bottom: rem(30px);
      margin-left: em(32px);
      margin-right: em(32px);
    }

    #{$b}__item {
      @include media-query(lg) {
        padding-left: rem(32px);
        padding-right: rem(32px);
      }
    }
  }
}
