// Lead
// ====
// Header/subheader
.o-lead {
  h1 + * {
    @include typi(h1-sub);
  }

  h2 + * {
    @include typi(h2-sub);
  }

  h3 + * {
    @include typi(h3-sub);
  }
}
