// Main footer
// ===========
.footer-main {
  $ctx: 13px; // set font size context

  padding: rem(70px) 0 rem(50px) 0;
  font-size: em(13px);
  background: color(abbey);
  color: color(white);

  @include media-range(phone) {
    padding-bottom: rem(80px);
  }

  // copyright text
  &__credit {
    display: table;
    width: 100%;
    margin-top: rem(30px);
    font-size: em(11px, $ctx);
    color: color(gray-chateau);

    @include media-range(phone) {
      font-size: em(8px, $ctx);
      padding-top: 3em;
    }

    p {
      @include media-query(sm) {
        display: table-cell;
        vertical-align: bottom;
      }
    }
  }

  &__authors {
    @include media-query(sm) {
      text-align: right;
    }

    a {
      color: color(white);
    }
  }

  .o-icon-facebook {
    max-width: rem(20px);
  }

  // set socials block to right side
  .c-socials {
    padding: 0;

    @include media-query(sm) {
      float: right;
      text-align: right;
    }
  }

  .c-list-links {
    @include media-range(phone) {
      font-size: em(12px, $ctx);
    }
  }
}
