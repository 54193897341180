// Icons
// ===========
// SVG Icons settings
// -----------
// - https://fvsch.com/code/svg-icons/how-to/#section-sprite
.o-icon {
  /* Lets the icon inherit the text color. */
  fill: currentColor;

  /* Inherit the text’s size too. Also allows sizing
     the icon by changing its font-size. */
  width: 1em;
  height: 1em;

  /* Nice visual alignment for icons alongside text.
     (I got a few questions about this and: with most
     fonts and styles, this works better than just
     vertical-align:middle. Try it and see what you
     like best. */
  vertical-align: -0.15em;

  /* Paths and strokes that overflow the viewBox can
     show in IE. If you use normalize.css, it already
     sets this. */
  overflow: hidden;

  use > svg {
    fill: currentColor;
  }

  &[data-rotate="90"] {
    transform: rotate(90deg);
  }

  &[data-rotate="-90"] {
    transform: rotate(-90deg);
  }

  &[data-rotate="180"] {
    transform: rotate(180deg);
  }

  &--static-lock {
    &.o-btn:before {
      content: "";
      display: inline-block;
      position: relative;
      height: 1.85em;
      width: 1.2em;
      margin-right: 5px;
      margin-top: -0.35em;
      margin-bottom: -0.35em;
      text-rendering: auto;
      mask: url(../../src/icons/lock.svg) no-repeat 100% 100%;
      mask-size: contain;
      background-color: white;

      @include media-range(tablet) {
        height: 1.8em;
      }
    }
  }
}
