// Breadcrumbs
// ===========
.c-breadcrumbs {
  min-height: rem(70px);
  font-size: rem(13px);

  &__list {
    @include list-unstyle;

    padding: rem(25px) 0;
  }

  &__item {
    display: inline-block;
    color: color(abbey);

    &:not(:last-of-type) {
      &::after {
        content: '/';
      }
    }
  }

  &__link {
    color: currentColor;
  }

  & + .l-2col {
    .l-2col__aside {
      margin-top: rem(-60px);
    }
  }
}
