// List materials
// ==============
// List - Bank of materials
.c-list-materials {
  &__list {
    @include list-unstyle;
  }

  &__item {
    // @extend components/_well.scss
    margin-bottom: rem(10px);

    .c-well__addon {
      @include media-range(phone) {
        display: block;
        width: auto;
        margin-left: rem(-15px);
        margin-right: rem(-15px);
        margin-bottom: rem(-20px);
      }
    }
  }

  &__content {
    display: table;
  }

  &__img {
    display: table-cell;
    width: 1%;
    padding-right: rem(30px);
    vertical-align: top;

    @include media-range(phone) {
      padding-right: rem(15px);
    }

    img {
      max-width: rem(80px);
    }
  }

  &__desc {
    @include media-query(sm) {
      padding-right: 25%;
    }
  }

  .c-tag {
    margin-left: rem(5px);
  }

  .c-pagination {
    margin-top: rem(40px);
  }

  .c-list-checkbox {
    margin-top: rem(50px);
    margin-bottom: rem(50px);

    @include media-range(phone) {
      margin-top: rem(30px);
      margin-bottom: rem(30px);
    }
  }
}
