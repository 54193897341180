// Button
// ======
.o-btn {
  $font-size-base: map-get($button-font-sizes, base);
  $font-size: map-get($font-size-base, md);

  position: relative;
  display: inline-block;
  padding: em(23px, $font-size) em(30px, $font-size);
  font-size: em($font-size);
  line-height: inherit; // from html -> 1.6
  font-weight: font-weight((primary, semi-bold));
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: color(white);
  background: color(primary, $colors-brand);
  cursor: pointer;
  transition: all 0.3s;
  z-index: 0;

  &::after {
    @include media-query(sm) {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.3s;
      width: 0%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: color(secondary, $colors-brand);
    }
  }

  &--red {
    font-weight: bold;
    color: color(black) !important;
    background-color: color(outrageous-orange) !important;
    &::after {
      background-color: color(mozna);
    }
  }

  // set colors for theme variants
  @include themify() {
    background-color: theme(primary);
    color: color(white);
  }

  // set hover and active state
  @include on-event('&.is-active') {
    background: color(primary, $colors-brand);
    color: color(white);

    &::after {
      width: 100%;
    }
  }

  @include media-range(mobile) {
    text-align: center;
  }

  @include media-range(phone) {
    display: block;
    padding: em(14px, $font-size) em(20px, $font-size);

    // specify selector + element
    // display block for button doesn't work
    // https://stackoverflow.com/questions/27605390/why-doesnt-display-block-width-auto-stretch-a-button-to-fill-the-contai
    @at-root button#{&} {
      width: 100%;
    }
  }

  @include media-range(tablet) {
    $font-size-base: map-get($button-font-sizes, base);
    $font-size: map-get($font-size-base, sm);

    font-size: em($font-size, sm);
    min-width: em(160px, $font-size);
    padding: em(10px, $font-size) em(25px, $font-size);
  }

  // reset colors for btn variants when .t-color is applied
  &[class^="o-btn--"],
  &[class*=" o-btn--"] {
    @include on-event('&.is-active') {
      &::after {
        width: 100%;
      }
    }
  }

  &.is-disabled,
  &.is-loading,
  &[disabled],
  &[data-disabled] {
    cursor: default;

    @include on-event('&, &.is-active') {
      background-color: #acacac;
    }
  }

  // apply styles when spinner is needed
  &.is-loading {
    position: relative;
  }

  // Block version / 100% width
  &--block {
    display: block;
    width: 100%;
  }

  // Gray background variant
  &--gray {
    background-color: color(abbey);

    // reset color when .t-color is applied
    @include themify() {
      background-color: color(abbey);
    }
  }

  // Blue background variant
  &--blue {
    color: color(white);
    background-color: color(deep-cerulean);

    // reset colors when theme is applied
    @include themify() {
      background-color: color(deep-cerulean);
      color: color(white);
    }
  }

  // White background variant
  &--white {
    margin: em(5px, $font-size); // margin is needed when button is located in element with overflow
    color: color(primary, $colors-brand);
    background-color: color(white);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    // reset colors when theme is applied
    @include themify() {
      background-color: color(white);
      color: color(abbey);
    }

    @include on-event('&.is-active') {
      background-color: color(white);
      color: color(white);
    }
  }

  // Small
  &--small {
    $font-size-small: map-get($button-font-sizes, small);
    $font-size: map-get($font-size-small, md);

    @include media-query(sm) {
      font-size: em($font-size);
      font-weight: font-weight((primary, normal));
      padding: em(12px, $font-size) em(28px, $font-size);
    }
  }

  // Small
  &--smaller {
    $font-size-small: map-get($button-font-sizes, small);
    $font-size: map-get($font-size-small, md);

    min-width: 135px;
    padding: em(3px, $font-size) em(5px, $font-size);
    text-align: center;
    text-transform: none;

    @include media-query(sm) {
      font-size: em($font-size);
      font-weight: font-weight((primary, normal));
    }
  }

  // when btn is a link with icon
  &--icon {
    // @extend objects/_links.scss
    position: relative;

    @include media-query(sm) {
      padding-right: 3.8em;
    }

    @include on-event('&.is-active') {
      .o-link {
        &__icon {
          border-color: color(white);
        }
      }
    }

    .o-link__icon {
      border-width: 1px;
      border-color: color(white);

      @include media-query(sm) {
        position: absolute;
        right: 1em;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .o-icon {
      @include themify() {
        color: color(white);
      }
    }
  }

  &--margin-right-s {
    margin-right: 1em;
  }

  &--margin-right-m {
    margin-right: 2em;
  }

  &--margin-right-l {
    margin-right: 4em;
  }
}
