// Webinar
// ===========
.m-webinar {
  // @extend components/_list-checkbox.scss
  .c-list-checkbox {
    margin-top: rem(40px);
    margin-bottom: rem(50px);
  }

  // @extend components/_list-conferences.scss
  .c-list-webinar {
    &__list {
      margin-bottom: rem(70px);
    }

    &__title {
      line-height: 1.26;
    }
  }

  // @extend components/_accordion.scss
  .c-accordion {
    &__wrapper {
      background: transparent;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      background-color: transparent;
    }
  }
}
