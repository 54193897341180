// Ribbon
// ======
.c-ribbon {
  $ctx: 14px; // set font size context
  display: inline-block;
  position: relative;
  left: -10px;
  padding: em(3px, $ctx) em(12px, $ctx);
  font-size: em($ctx);
  text-transform: uppercase;
  font-weight: font-weight((primary, semibold));
  color: color(white);
  background: color(morning-glory);

  &-simple {
    &--green {
      background: color(pistachio);
    }

    &--blue {
      background: color(blue-stone);
    }

    &--rose {
      background: color(rose);
    }

    &--orange {
      background: color(mango-tango);
    }
  }

  &::after:not(.c-ribbon-simple*) {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 0;
    border-color: transparent darken(desaturate(color(morning-glory), 25%), 15%) transparent transparent;
  }

  &--green {
    background: color(pistachio);

    &::after {
      border-color: transparent darken(desaturate(color(pistachio), 25%), 15%) transparent transparent;
    }
  }

  &--blue {
    background: color(blue-stone);

    &::after {
      border-color: transparent darken(desaturate(color(blue-stone), 25%), 15%) transparent transparent;
    }
  }

  &--rose {
    background: color(rose);

    &::after {
      border-color: transparent darken(desaturate(color(rose), 25%), 15%) transparent transparent;
    }
  }

  &--orange {
    background: color(mango-tango);

    &::after {
      border-color: transparent darken(desaturate(color(mango-tango), 25%), 15%) transparent transparent;
    }
  }

  &.product {
    $ctx: 12px;

    position: absolute;
    margin: auto;
    left: 1.7rem;
    width: 40%;
    padding: em(3px, $ctx) em(12px, $ctx);
    text-align: center;
    font-weight: 600;

    font-size: em($ctx);

    &:nth-of-type(1) {
      bottom: 3rem;
    }

    &:nth-of-type(2) {
      bottom: 5rem;
    }

    &:nth-of-type(3) {
      bottom: 7rem;
    }
  }

  &.list-view {
    $ctx: 10px;

    position: absolute;
    margin: auto;
    left: 2.8rem;
    width: 6rem;
    font-weight: 600;
    padding: em(3px, $ctx) em(12px, $ctx);
    font-size: em($ctx);

    &:nth-of-type(1) {
      top: 4rem;
    }

    &:nth-of-type(2) {
      top: 6rem;
    }

    &:nth-of-type(3) {
      top: 8rem;
    }
  }

  &.school-list-view {
    $ctx: 9px;
    position: absolute;

    padding: em(3px, $ctx) em(12px, $ctx);
    font-size: em($ctx);
  }
}

#tx-solr-search .c-ribbon {
  $ctx: 9px;

  position: absolute;
  margin: auto;
  left: 3rem;
  width: 5.6rem;

  padding: em(3px, $ctx) em(12px, $ctx);
  font-size: em($ctx);
  font-weight: bold;

  &:nth-of-type(1) {
    top: 4.2rem;
  }

  &:nth-of-type(2) {
    top: 6rem;
  }

  &:nth-of-type(3) {
    top: 7.8rem;
  }
}
