// Video hedaer
// =========
.c-video-header {
  $b: &;

  position: relative;
  padding: rem(60px) rem(75px) rem(60px) rem(25px);
  background-color: color(white);

  @include media-range(phone) {
    padding: 0;
    background-color: transparent;
  }

  &__img {
    position: absolute;
    top: rem(70px);
    left: rem(30px);
    width: rem(115px);
    height: rem(115px);

    @include media-range(phone) {
      width: rem(58px);
      height: rem(58px);
      top: rem(45px);
      left: rem(15px);
    }

    img {
      min-width: rem(115px);
      border-radius: 50%;
      border: 3px solid #ffb81c;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

      @include media-range(phone) {
        min-width: rem(58px);
      }
    }

    & + #{$b}__desc {
      padding-left: rem(150px);

      @include media-range(phone) {
        padding-left: 0;
      }

      & #{$b}__name {
        @include media-range(phone) {
          min-height: rem(120px);
          padding-left: rem(90px);
        }
      }
    }
  }

  &__desc {
    @include media-range(phone) {
      padding-left: 0;
    }
  }

  &__name {
    width: 100%;
    margin-bottom: rem(20px);
    font-family: font-family(secondary);
    font-size: rem(20px);
    color: color(blue-stone);

    @include media-range(phone) {
      margin-bottom: rem(10px);
      padding: rem(50px) rem(15px) rem(15px) rem(15px);
      font-size: rem(18px);
      line-height: 1.33;
      background-color: color(white);
    }
  }

  &__category {
    width: 100%;
    margin-bottom: 0;
    font-size: rem(14px);
    text-transform: uppercase;
    color: color(blue-stone);

    @include media-range(phone) {
      margin-bottom: rem(5px);
    }
  }

  &__title {
    width: 100%;
    line-height: 1.33;
    font-size: rem(36px);
    font-family: font-family(secondary);
    color: color(blue-stone);
    margin-bottom: rem(20px);

    @include media-range(phone) {
      margin-bottom: rem(15px);
      font-size: 18px;
    }
  }

  &__text {
    width: 100%;
    margin-bottom: rem(35px);

    @include media-range(phone) {
      margin-bottom: rem(20px);
      line-height: 1.3;
    }
  }

  .c-ribbon {
    position: absolute;
    top: em(20px);
    padding: em(2px) em(19px);

    @include media-range(phone) {
      font-size: 10px;
      top: em(15px);
      padding: em(2px) em(20px);
    }
  }
}
