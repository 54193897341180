// Video tabs
// =========
.c-video-banner {
  $b: &;

  &::after {
    content: " ";
    display: block;
    clear: both;
  }

  &__box {
    display: none;

    &.is-open {
      display: block;
    }

    &-wrapper {
      float: right;

      @include media-range(phone) {
        margin-bottom: 35px;
      }
    }

    #{$b}__desc {
      padding-top: rem(25px);

      @include media-range(phone) {
        padding-top: rem(20px);
        padding-left: 0;
      }
    }

    #{$b}__title {
      margin-bottom: rem(25px);
      font-size: rem(18px);

      @include media-range(phone) {
        margin-bottom: rem(10px);
        font-size: rem(15px);
      }
    }

    #{$b}__tag {
      font-size: rem(15px);
      margin-bottom: rem(3px);

      @include media-range(phone) {
        margin-bottom: 0;
        line-height: 1;
      }
    }

    #{$b}__text {
      font-size: rem(15px);
      line-height: 1.3;
    }
  }

  &__list {
    h3,
    .h3 {
      color: color(blue-stone);
    }
  }

  &__item {
    display: table;
    width: 100%;
    margin-bottom: rem(10px);
    padding: rem(5px);
    background-color: #b6e1d5;

    @include media-range(phone) {
      margin-bottom: 0;
      background-color: transparent;
    }

    &:hover {
      background-color: color(white);
    }
  }

  &__img {
    display: table-cell;
    width: 44%;
    vertical-align: middle;

    @include media-range(phone) {
      width: 34%;
    }

    img {
      width: 100%;
    }
  }

  &__desc {
    display: table-cell;
    width: 56%;
    padding-left: rem(25px);
    vertical-align: middle;

    @include media-range(phone) {
      width: 76%;
      padding-left: rem(10px);
    }
  }

  &__tag {
    font-size: rem(12px);
    color: color(blue-stone);
    font-weight: font-weight((primary, semi-bold));
  }

  &__title {
    font-size: rem(15px);
    color: color(black);
  }
}
