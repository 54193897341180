.#{$m}-reg {
  margin-bottom: rem(80px);

  @include media-range(phone) {
    margin-bottom: rem(55px);
  }

  &__header {
    display: table;
    width: 100%;

    @include media-range(phone) {
      display: block;
    }
  }

  &__table {
    overflow-x: auto;
    margin-bottom: rem(30px);

    @include media-range(phone) {
      margin-bottom: rem(20px);
      margin-right: rem(-15px);
    }

    table {
      margin-bottom: 0;

      @include media-range(phone) {
        white-space: nowrap;
      }
    }
  }

  &__title {
    display: table-cell;
    width: 1%;
    margin: 0;
    font-family: font-family(secondary);
    font-weight: font-weight((secondary, normal));
    white-space: nowrap;

    @include typi(h1, $rem: false, $typi-breakpoint: mappy-bp);

    @include media-range(phone) {
      display: block;
      width: 100%;
      margin-bottom: rem(15px);
      white-space: normal;
    }

    @include media-query(sm lg) {
      display: block;
      width: auto;
    }
  }

  .c-progress {
    display: table-cell;
    padding-left: rem(50px);

    @include media-range(phone) {
      display: block;
      margin-bottom: rem(20px);
      padding-left: 0;
    }

    @include media-query(sm lg) {
      display: block;
      padding-left: 0;
    }
  }

  &__btns {
    &-top {
      display: table-cell;
      vertical-align: middle;
      text-align: right;

      @include media-range(phone) {
        display: block;
      }

      @include media-query(sm lg) {
        vertical-align: bottom;
      }
    }

    &-bottom {
      text-align: right;
    }

    &-top,
    &-bottom {
      .simplert__footer {
        .o-btn {
          min-width: 0;
        }
      }

      .o-btn {
        min-width: rem(175px);
        text-align: center;

        &:not(:last-child) {
          margin-right: rem(20px);

          @include media-range(phone) {
            margin-right: 0;
            margin-bottom: rem(10px);
          }
        }
      }
    }
  }

  .o-form {
    @include media-range(phone) {
      margin-top: rem(30px);
    }
  }
}
