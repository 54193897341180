// Schedule
// ==============
.c-schedule {
  $b: &;

  &__list {
    margin-top: rem(30px);
  }

  &__header {
    margin-bottom: rem(8px);
    font-size: rem(24px);
    font-family: font-family(secondary);
    color: color(blue-stone);

    @include media-range(phone) {
      font-size: rem(18px);
      margin-bottom: rem(5px);
    }
  }

  &__item {
    margin-bottom: rem(10px);
    padding: 1.66667em;
    background: #fff;
    display: table;
    width: 100%;

    @include media-range(phone) {
      padding: rem(5px) rem(15px);
    }

    &-header {
      padding: rem(15px) rem(30px);
      background-color: color(blue-stone);
      color: color(white);

      #{$b}__header {
        margin-bottom: 0;
        color: color(white);

        @include media-range(phone) {
          display: flex;
          flex-wrap: nowrap;
        }

        .o-icon {
          width: rem(16px);
          height: rem(16px);

          @include media-range(phone) {
            width: rem(21px);
            height: rem(20px);
          }
        }
      }

      .o-icon {
        vertical-align: top;
        margin-top: rem(10px);
        margin-right: rem(15px);

        @include media-range(phone) {
          margin-top: rem(5px);
        }
      }
    }
  }

  &__content {
    display: table;
    vertical-align: top;
    margin-top: rem(45px);

    @include media-range(phone) {
      display: block;
      width: 100%;
      margin-top: 25px;
    }
  }

  &__img {
    display: table-cell;
    vertical-align: top;
    width: rem(115px);

    @include media-range(phone) {
      display: block;
      width: 100%;
    }

    img {
      max-width: 100%;
      border-radius: 50%;
      border: 3px solid color(my-sin);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }

  &__desc {
    display: table-cell;
    vertical-align: top;
    padding-left: rem(30px);

    @include media-range(phone) {
      display: block;
      width: 100%;
      padding-left: 0;
    }
  }

  &__name {
    margin-bottom: rem(15px);
    font-family: font-family(secondary);
    font-size: rem(20px);

    @include media-range(phone) {
      margin-bottom: 0;
    }

    span {
      position: relative;
      display: inline-block;
      margin-left: rem(10px);

      &::before {
        position: absolute;
        top: calc(50% - #{rem(8px)});
        left: rem(-8px);
        content: "";
        display: block;
        height: rem(20px);
        width: rem(2px);
        background-color: #6b6b6b;
      }
    }
  }

  &__subtitle {
    margin-bottom: rem(10px);

    .o-icon {
      margin-right: rem(10px);

      @include media-range(phone) {
        margin-right: rem(5px);
      }
    }
  }

  &__time {
    margin-right: rem(25px);

    @include media-range(phone) {
      display: block;
      margin-right: 0;
    }

    .o-icon {
      width: rem(16px);
      height: rem(16px);

      @include media-range(phone) {
        width: rem(20px);
        height: rem(20px);
      }
    }

    span {
      @include media-range(phone) {
        vertical-align: text-bottom;
      }
    }
  }

  &__lang {
    @include media-range(phone) {
      display: block;
    }

    .o-icon {
      width: rem(18px);
      height: rem(18px);

      @include media-range(phone) {
        width: rem(20px);
        height: rem(20px);
      }
    }

    span {
      @include media-range(phone) {
        vertical-align: text-bottom;
      }
    }
  }

  &__text {
    p {
      margin-bottom: rem(22px);

      @include media-range(phone) {
        line-height: 1.26;
      }
    }
  }
}
