// Book item
// ==========
// Book item in user account
// @usage components/_well.scss
.c-book {
  position: relative;
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.04);

  @include media-range(phone) {
    padding-top: rem(60px);
  }

  &__img {
    padding: 0 rem(80px) 0 rem(30px);

    @include media-range(phone) {
      display: block;
      margin: 0 auto;
      padding: 0;
      width: auto;
    }

    .c-product {
      width: rem(245px);
      padding-bottom: rem(20px);

      &__img {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    padding-top: rem(10px);
  }

  &__title {
    font-family: font-family(secondary);
    font-size: em(22px);

    @include media-range(phone) {
      font-size: em(18px);
    }
  }

  &__data {
    p {
      margin-bottom: rem(5px);
    }
  }

  &__btns {
    @include media-range(phone) {
      display: block;
      width: auto;
      position: absolute;
      top: rem(15px);
      right: rem(15px);
    }
  }

  &__btn {
    display: inline-block;
    margin-left: rem(15px);

    .o-icon {
      width: rem(20px);
      height: rem(20px);
      color: color(blue-stone);
    }
  }

  .c-header__addon {
    vertical-align: top;
  }
}
