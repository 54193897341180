// Main header
// ===========
.header-main {

  // middle part with logo and quick links
  &__mid {
    position: relative;
    display: table;
    width: 100%;

    @include media-range(phone) {
      position: relative;
    }

    &-searchless {
      padding-right: rem(10px);
    }
  }

  &__logo,
  &__quick {
    display: table-cell; // set elements as table-cell
    vertical-align: middle; // to simple vertical-align
  }

  // quick links on the right side
  &__quick {
    text-align: right;

    @include media-range(phone) {
      width: 65%;
      padding-left: rem(20px);
    }
  }

  &__country {
    padding-left: 5px;
    padding-top: 6px;
    vertical-align: middle;
    display: inline-block;
    font-weight: 600;

    @include media-range(tablet) {
      padding-top: 4px;
    }

    @include media-range(phone) {
      padding-top: 1px;
    }

    &::before {
      content: '|';
      vertical-align: text-bottom;
    }
  }

  // set nav-switch component on mobile
  .c-nav-switch {
    @include media-range(phone) {
      @include grid-pull-out-container();
    }
  }

  // set quick as relative to keep search form
  .c-nav-quick {
    z-index: 1;
  }

  // set various width on responsive
  .c-search-form {
    &--expand {
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: rem(50px);
      max-width: rem(685px);
      z-index: 0;

      @include media-range(tablet) {
        max-width: rem(600px);
      }

      @include media-range(phone) {
        left: 0;
        max-width: none;
      }

      .o-close {
        @include media-range(phone) {
          right: rem(10px);
        }
      }

      &.is-expanded {
        z-index: 2;
      }
    }
  }
}
