//Tabs
.c-tabs {
  $b: &;

  &__list {
    @include list-unstyle;
    @include grid-make-default-row();

    display: flex;
  }

  &__item {
    display: flex;
    flex: auto;
    padding-left: gutter(map-get($susy-responsive, lg));
    padding-right: gutter(map-get($susy-responsive, lg));

    @include media-range(phone) {
      padding-left: gutter(map-get($susy-responsive, xs));
      padding-right: gutter(map-get($susy-responsive, xs));
    }

    @include media-range(tablet) {
      padding-left: gutter(map-get($susy-responsive, sm));
      padding-right: gutter(map-get($susy-responsive, sm));
    }

    @include media-range(medium) {
      padding-left: gutter(map-get($susy-responsive, md));
      padding-right: gutter(map-get($susy-responsive, md));
    }

    &:hover,
    &.is-open {
      #{$b}__link {
        background-color: color(blue-stone);
        color: color(white);

        @each $color in $u-colors-block {
          $key: nth($color, 1);
          $val: nth($color, 2);

          &-#{$key} {
            background-color: $val;
          }
        }
      }
    }
  }

  &__link {
    display: block;
    width: 100%;
    padding: em(30px);
    text-align: center;
    background-color: color(white);
    color: color(blue-stone);
    transition: all 0.3s;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    @each $color in $u-colors-block {
      $key: nth($color, 1);
      $val: nth($color, 2);

      &-#{$key} {
        border-bottom: 6px solid $val;

        &:hover {
          background-color: $val;
        }
      }
    }
  }

  &__content {
    display: none;
  }

  &__menu {
    margin-bottom: em(20px);

    @include media-range(phone) {
      margin-bottom: em(10px);
    }

    &.school-select {
      margin-bottom: 0;
      .o-select {
        height: 2.65em;
      }
    }
  }

  &__select-title {
    margin-top: em(30px);
    font-size: em(18px);
    font-weight: font-weight((primary, semi-bold));
  }

  &--menu {
    #{$b}__list {
      background-color: color(white);
      margin-left: 0;
      margin-right: 0;
    }

    #{$b}__link {
      background: color(white);
    }

    #{$b}__item {
      position: relative;
      padding-left: 0;
      padding-right: 0;

      &::after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 4px;
        background: color(primary, $colors-brand);
        content: '';
        opacity: 0;
        transition: opacity $animation-time $animation-function, transform $animation-time $animation-function;
        transform: translateY(6px);
      }

      &:active,
      &:focus,
      &:hover,
      &.is-open {
        &::after {
          opacity: 1;
          transform: translateY(-4px);
        }

        .c-tabs__link {
          background: color(white);
          color: color(blue-stone);
        }
      }
    }
  }

  &--switch {
    &.is-scrollable {
      #{$b}__menu {
        display: flex;
        position: relative;
      }

      #{$b}__list {
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: rem(20px);
          opacity: 0;

          @include transition(opacity, .15s);
        }

        &::before {
          left: rem(40px);
          background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        &::after {
          right: rem(40px);
          background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
      }

      &--left {
        #{$b}__list {
          &::before {
            opacity: 1;
          }
        }
      }

      &--right {
        #{$b}__list {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    #{$b}__list {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      overflow: hidden;
    }

    #{$b}__item {
      padding-left: rem(1px);
      padding-right: rem(1px);
      align-self: flex-end;
      border-bottom: rem(1px) solid transparent;
      flex: 0 0 auto;
      min-width: rem(220px);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:only-child {
        #{$b}__link {
          width: auto;
        }
      }

      &.is-open {
        border-bottom-color: color(porcelain);

        #{$b}__link {
          padding: em(30px) em(15px);
          background-color: color(porcelain);
          color: color(balck);
          font-weight: font-weight((primary, semi-bold));
        }
      }

      #{$b}__link {
        padding: em(15px);
        background-color: color(athens-gray);
        color: color(black);
        text-align: left;

        p:last-of-type {
          margin: 0;
        }
      }
    }

    #{$b}__menu {
      margin-bottom: 0;
    }

    #{$b}__content {
      padding: rem(13px);
      background-color: color(porcelain);
    }

    #{$b}__select-title {
      display: none;
    }

    #{$b}__btn {
      height: rem(55px);
      min-width: rem(40px);
      align-self: flex-end;
      cursor: pointer;

      @include transition(opacity, .15s);

      &:focus {
        outline: 0;
      }

      &[disabled] {
        opacity: 0.2;
        cursor: default;
      }
    }
  }

  .ps.ps--active-x > .ps__scrollbar-x-rail,
  .ps.ps--active-y > .ps__scrollbar-y-rail {
    display: none;
  }

  &--img {
    .c-tabs__list {
      display: block;
    }
  }

  .tab-disabled {
    pointer-events: none;
    a {
      color: color(white);
      background-color: color(gray-chateau);
    }
  }
}
