// Animations
// =========
// General animations for projects

@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }

  50% {
    opacity: 0;
    transform: translate(-100%);
  }

  51% {
    opacity: 1;
  }
}

@keyframes toBottomFromTop {
  49% {
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
    transform: translateY(-100%);
  }

  51% {
    opacity: 1;
  }
}
