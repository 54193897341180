// Header
// =========
// Title + link on right side
.c-header {
  display: table;
  width: 100%;

  > * {
    margin-bottom: 0;
  }

  &__addon {
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }
}
