// Quick navigation
// ================
.c-nav-quick {
  &__list {
    @include list-unstyle;

    @include media-range(tablet) {
      font-size: 14px;
    }
  }

  &__item {
    display: inline-block;
    margin: 0 em(30px);

    @include media-range(tablet) {
      margin: 0 em(15px);
    }

    &:last-of-type {
      margin-right: 0;
    }

    @include media-range(phone) {
      margin-right: 0;
    }
  }

  &__link {
    text-transform: uppercase;
    color: color(black);
    font-weight: font-weight((primary, semi-bold));
  }

  .o-icon {
    height: rem(11px);
    margin-right: 5px;
    vertical-align: 0;
    color: color(abbey);

    @include media-range(phone) {
      height: rem(18px);
      width: rem(18px);
    }
  }
}
