// Title
// =====
.u-title {
  $b: &;

  position: relative;

  &__content {
    position: absolute;
    bottom: rem(-10px);
    right: rem(-10px);
    display: block;
    background-color: #fffef2;
    border: 1px solid black;
    padding: rem(2px) rem(18px);
    color: black;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
    font-size: 10px;
    line-height: 1;
    z-index: 2;
  }

  &:hover {
    & + #{$b}__content,
    #{$b}__content {
      opacity: 1;
      transition: all 0.3s ease-in-out 0.5s;
    }
  }
}
