// Perfect-scrollbar
// ========
// Plugin for scroll
// @vue-component scroll-area.vue

// Import source file
@import './node_modules/perfect-scrollbar/src/css/main.scss';

.ps {
  $b: &; // set block context

  &--is-hidden {
    .ps__scrollbar-x-rail,
    .ps__scrollbar-y-rail {
      opacity: 0;
    }

    &:hover {
      > #{$b}__scrollbar {
        &-x-rail,
        &-y-rail {
          opacity: 0;
          background-color: transparent;

          &:hover {
            .ps__scrollbar-x,
            .ps__scrollbar-y {
              opacity: 0;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

