// Content
// ======
// Single product content
.#{$m}-content {
  position: relative;

  &__menu {
    position: sticky;
    top: rem(10px);
    z-index: 3;
  }

  &__title {
    margin-bottom: rem(25px);
  }

  &__unavailable {
    font-size: rem(12px);
  }

  .o-icon-check {
    color: color(blue-stone);
  }

  small {
    font-size: rem(12px);
  }

  .u-table {
    thead {
      th:last-child {
        min-width: 162px;
      }
    }
  }
}
