// Component
// =========
// Prize form

.row {
  &--flex-end {
    @media (min-width: 762px) {
      display: flex;
      align-items: flex-end;
    }
  }
}
