// Checkbox
// ====
// Basic checkbox form
.o-checkbox {
  position: absolute;
  z-index: -1000;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  & + label {
    display: inline-block;
    position: relative;
    margin-right: rem(11px);
    width: em(16px);
    height: em(16px);
    border-left: 1px solid rgba(0, 0, 0, 0.35);
    border-top: 1px solid rgba(0, 0, 0, 0.35);
    background-color: white;
    cursor: pointer;
  }

  &-showall {
    & + label {
      margin: rem(6px) 0;
      margin-left: rem(11px);
    }
    @include media-range(phone) {
      margin-bottom: rem(10px);
    }
  }

  &:checked + label::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: em(15px);
    height: em(15px);
    margin: auto;
    background-image: url(../img/icons/check.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 85%;
  }

  &--red {
    &:checked + label::before {
      background-image: url(../img/icons/red-check.svg);
    }
  }

  @include on-event() {
    outline: 0;
  }
}

.o-checkbox-disabled {
  pointer-events: none;
}
