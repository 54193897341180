// Badge level
// ===========
.c-badge-level {
  position: relative;
  display: inline-block;
  height: em(28px);
  width: em(28px);
  color: color(primary, $colors-brand);
  border: 2px dashed color(deep-cerulean);
  border-radius: 100%;

  > span {
    @include transform-center($horizontal: false);

    left: 0;
    right: 0;
    display: block;
    text-align: center;
    color: color(deep-cerulean);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1px;
      background: color(deep-cerulean);
    }

    span {
      display: block;
      font-size: em(9px);
      text-transform: uppercase;
      line-height: 1.4;
      font-weight: font-weight((primary, semi-bold));
      color: currentColor;
    }
  }

  &--one-lvl {
    > span {
      &::before {
        display: none;
      }
    }
  }

  // bigger version
  &--big {
    height: em(37px);
    width: em(37px);

    > span {
      &::before {
        height: 2px;
      }

      span {
        line-height: 1.6;
        font-size: .7em;
      }
    }
  }

  // GSE LEVEL
  &--gse {
    border-style: solid;

    > span {
      &::before {
        height: 2px;
      }
    }

    .gse {
      position: absolute;
      display: block;
      right: 100%;
      top: 0;
      bottom: 0;
      margin: auto rem(-3px) auto;
      width: rem(18px);
      height: rem(18px);
      line-height: rem(18px);
      font-size: em(7px);
      text-align: center;
      border: 1px solid color(deep-cerulean);
      border-radius: 100%;
      background: color(white);
      color: color(deep-cerulean);
    }
  }

  &--black {
    color: color(black);
    border-color: color(black);

    span {
      color: color(black);
    }
  }
}
