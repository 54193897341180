// Burger
// =====
// Burger menu button
.o-burger {
  $width: 20px;
  $thickness: 2px;

  display: inline-block;
  border: 0;
  background: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  border-bottom: $thickness solid currentColor;
  width: $width;

  // Fix for extra space in Firefox
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    border-bottom: $thickness solid currentColor;
    width: 100%;
    margin-bottom: 4px;
  }

  &.is-open {
    border-bottom-color: transparent;

    &::before {
      transform: rotate(135deg);
      margin-bottom: -2px;
    }

    &::after {
      transform: rotate(-135deg);
      margin-bottom: 0;
    }
  }
}
