//Quiz
.c-quiz {

  .c-quiz-result {
    display: none;
  }
  .question {
    margin: 2em 0;
  }
  [data-question-info] {
    display: none;
    margin: 1em 0;
  }

  .questions {
    margin-bottom: 2em;
  }
  .c-quiz-result {
    margin-top: 1em;
  }

  .control-buttons {
    .quiz-button {
      max-width: 30%;
      margin-left: 20%;
      display: inline-block;
      color: color(gray-chateau);
      &:not(:first-of-type) {
        margin-left: 10%;
      }
      &:hover {
        cursor: pointer;
        color: color(pistachio)
      }
      &.active {
        color: color(pistachio)
      }
    }
  }

  .close-button {
    &:hover {
      cursor: pointer;
      color: color(pistachio)
    }
  }
  .question-container .answers-container {
    .o-btn {
      opacity: 0.3;
      &.selected {
        opacity: 1;
      }
      &:hover {
        opacity: 1;
        &:after {
          width: 0;
        }
      }

    }
  }
}
