// Data
// ====
// Listing of user data
.#{$m}-data {

  // @extend components/_header.scss
  .c-header {
    margin-bottom: rem(40px);

    @include media-range(phone) {
      margin-bottom: rem(20px);
    }

    .o-icon {
      font-size: em(20px);
      color: color(primary, $colors-brand);
    }
  }

  &__list {
    display: table;
  }

  &__row {
    @include media-query(sm) {
      display: table-row;

      > * {
        display: table-cell;
      }
    }

    @include media-range(phone) {
      margin-bottom: rem(20px);
    }
  }

  .label {
    font-weight: font-weight((primary, semi-bold));

    @include media-query(sm) {
      padding-right: rem(20px);
      text-align: right;
    }
  }

  &--left {
    text-align: left;
  }
}
