// Pearson bank
// ===========
$bankMainColor: #2e7fa1;
$bankActiveColor: #ea7600;

.c-bank {
  .main-title {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;

    @include media-range(phone) {
      margin-left: 0.2em;
      margin-right: 0.2em;
    }
  }

  .resource-title {
    font-size: 1.3em
  }

  a.c-list-materials__item {
    display: block;
    padding-right: 2em;
    padding-bottom: 2.5em;

    .o-link {
      position: absolute;
      right: 3.5em;

      .o-link__icon {
        width: 1.5em;
        height: 1.5em;
        border-color: $bankMainColor;

        .o-icon {
          color: $bankMainColor;
        }
      }

      svg {
        width: .9em;
        height: .9em;
      }
    }

    &:hover {
      .c-card__title {
        color: $bankActiveColor;
      }

      .o-link {
        .o-link__icon {
          border-color: $bankActiveColor;

          .o-icon {
            color: #FFFFFF !important;
          }
        }
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      align-content: stretch;

      @include media-range(phone) {
        display: block;
      }

      .tag-container {
        .bank-tag {
          padding: 0;

          &:after {
            content: ',';
            margin-right: 0.4em;
          }

          &:last-of-type {
            &:after {
              content: '';
            }
          }

          &.theme {
            text-transform: uppercase;
          }
        }
      }
    }

    .bank-tag {
      font-size: 87%;
    }
  }

  .resource-detail {
    .resource {
      justify-content: space-between;
      margin: 0 .5em 1.5em .5em;
      color: #505759;
      position: relative;
      padding: 1.64286em 2.14286em;
      font-size: .93333em;
      line-height: inherit;
      font-weight: 600;
      letter-spacing: .075em;
      background: #FFFFFF;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
      box-shadow: 0 0 10px rgba(0, 0, 0, .15);

      .tag-container {
        margin-bottom: 0;
        font-size: 0.73em;

        .bank-tag {
          font-weight: normal;
          padding: 0;

          &:after {
            content: ',';
          }

          &:last-of-type {
            &:after {
              content: '';
            }
          }

          &.tag-category {
            text-transform: initial;

            &:after {
              content: ''
            }
          }
        }

        .tag-category {
          font-weight: bold;
          position: relative;
        }
      }

      .resource-contents {
        margin: 1em 0 2em 0;
        padding: 0;

        .col-sm-4 {
          padding: 0;

          .resource-files {
            > a.o-btn {
              @include media-query(sm) {
                min-width: 0;
              }
            }
          }
        }
      }
    }
  }

  span.bank-tag {
    display: inline-block;
    padding: 0 10px;
    color: #505759;
    margin-bottom: 0.4em;
    font-weight: bold;
    position: relative;
    z-index: auto;

    &.special {
      color: #d0d6d7;
      border-color: #e6ebec;
      margin-bottom: 0;

      &:before {
        content: "";
        position: absolute;
        left: 15%;
        bottom: 0;
        height: 1px;
        width: 70%;
        border-bottom: 1px solid #e6ebec;
      }

      &.active {
        color: #505759;
      }
    }
  }

  .resource-listitem {
    width: 65%;
    display: inline-flex;
    flex-direction: column;

    @include media-range(phone) {
      width: 100%;
    }

    > .c-card__title {
      font-family: unset;
      color: $bankMainColor;
      margin-top: 0;
    }

    > p {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin-bottom: 0;

      .c-bank-desc {
        align-self: center;
      }

      &.c-card__desc.tags {
        span.bank-tag {
          padding: 0;
          margin: 0 0.3em 0.6em 0;
          align-self: center;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .tag-section {
    width: 34%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    text-align: center;

    > div {
      margin-left: 0.3em;

      div {
        > div:last-child {
          span.bank-tag {
            &:before {
              height: 0;
              border-width: 0;
            }
          }
        }
      }
    }

    span {
      width: 100%;
    }
  }

  .bank-icon {
    padding: 1em;

    &:first-of-type {
      margin-left: 0;
      @include media-range(phone) {
        margin-left: 0.5em;
      }
    }

    svg {
      display: block;
      width: 65px;
      height: 25px !important;
    }

    &--description {
      display: inline-block;
      margin-top: 0.5em;
      text-align: center;

      span.icon-title {
        display: block;
        margin-top: 0.5em;
        text-transform: lowercase;
      }
    }
  }

  .bank-download-all {
    margin-bottom: 1.5em;

    .bank-icon--description {
      .icon-title {
        text-transform: uppercase;
      }
    }
  }

  .c-card__desc {
    margin-bottom: 0.5em
  }

  .search-filters {
    input[type=submit] {
      margin-top: 1em;
    }

    .category.search {
      > span {
        width: 80%
      }

      @include media-range(phone) {
        width: 100%
      }
    }

    div.category {
      border: 1px solid #dce2e3;
      margin-bottom: 1.5em;
      font-size: 90%;

      .category-content {
        @include transition();

        height: 0;
        overflow: hidden;
        backface-visibility: hidden;
        will-change: height;

        .u-input-group {
          &:first-of-type {
            margin-top: 0.6em;
          }

          @include media-range(phone) {
            margin-bottom: 1.2em;
          }
        }
      }

      .category-title {
        color: #505759;
        font-size: 90%;
        background-color: #dce2e3;
        padding: 0.5em;
        cursor: pointer;

        .category-title--main, .c-bank__addon {
          display: inline-block;
        }

        .category-title--main {
          width: 85%;
        }

        .c-accordion__addon {
          width: 10%;
          text-align: right;
          color: #505759;
        }

        .c-bank__icon-hide {
          display: none;
        }

        &.is-open {
          .c-bank__icon-show {
            display: none;
          }

          .c-bank__icon-hide {
            display: inline-block;
          }
        }
      }

      &.search {
        border: 0;

        > span {
          display: inline-block;

          &.search-button-container {
            text-align: right;

            .o-btn-search {
              padding-top: 0.86em;
              padding-bottom: 0.86em;
              line-height: 1.4em;

              @include media-query(xs) {
                display: inline;
                padding: 1em;
              }
            }
          }

          input {
            width: 100%;
            margin-top: 0;
          }
        }
      }

      .search-text {
        border: 1px solid #dce2e3;
        padding: 0.7em 0.5em;
        color: #505759;
        background-color: #FFFFFF;
      }

      .u-input-group {
        margin-bottom: 0.2em;
        margin-left: 1em;
        font-size: 90%;
        color: #505759;

        &:last-of-type {
          margin-bottom: 1em;
        }

        .u-form-control {
          margin-right: 1.5em;
        }
      }
    }
  }

  .no-results {
    color: #505759;
    text-align: center;

    @include media-range(phone) {
      margin-bottom: 2em;
    }

    .search-again {
      margin-top: 2em;
    }

    .bank-image {
      max-width: 300px;
      @include media-range(phone) {
        max-width: 200px;
      }
    }
  }

  .c-pagination {
    .c-pagination__item {
      cursor: pointer;

      &.c-pagination__item--less, &.c-pagination__item--more {
        cursor: auto;
      }
    }

    li.c-pagination__item {
      display: inline-block !important;

      &.c-pagination__item--next, &.c-pagination__item--prev {
        @include media-range(phone) {
          margin-right: 0.33333rem;
          margin-left: 0.33333rem;
        }
      }
    }
  }
}

