// List webinars
// ==============
.c-list-webinar {
  $b: &;

  &__list {
    margin-top: rem(30px);
  }

  &__img {
    display: table-cell;
    width: 1%;
    vertical-align: top;
    padding-top: rem(20px);

    @include media-range(phone) {
      display: block;
      margin-bottom: 15px;
    }

    img {
      min-width: rem(115px);
      margin-right: rem(30px);
      border-radius: 50%;
      border: 3px solid #ffb81c;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }

  &__tag {
    margin-bottom: rem(5px);
    font-size: rem(14px);
    text-transform: uppercase;
    color: color(blue-stone);
  }

  &__item {
    margin-bottom: rem(10px);

    @include media-range(phone) {
      padding-bottom: rem(10px);
    }

    &:hover {
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    }

    .c-well__addon {
      text-align: center;

      @include media-range(phone) {
        display: block;
        width: auto;
        margin-left: rem(-15px);
        margin-right: rem(-15px);
      }

      @include media-range(medium) {
        display: block;
        width: 100%;
      }
    }

    .o-btn {
      display: block;
      padding-right: em(22px);
      padding-left: em(22px);

      &:not(:last-child) {
        margin-bottom: em(10px);
      }

      @include media-range(medium) {
        display: inline-block;
        width: 50%;
      }
    }
  }

  &__content {
    display: table;
    width: 100%;
    padding-right: rem(50px);

    @include media-range(phone) {
      display: block;
      padding-right: 0;
    }

    @include media-range(medium) {
      display: block;
      padding-right: 0;
      margin-bottom: rem(13px);
    }
  }

  &__title {
    @include media-range(phone) {
      margin-bottom: rem(20px);
      font-size: 18px;
    }

    &,
    a:not(:hover) {
      color: color(blue-stone);
    }
  }

  &__pairs {
    display: flex;
    justify-content: space-between;

    @include media-range(phone) {
      display: block;
      margin-bottom: rem(15px);
    }
  }

  &__pairs-group {
    display: table-cell;
    padding-right: rem(15px);

    @include media-range(phone) {
      display: block;
    }

    & #{$b}__pair {
      display: table-row;

      @include media-range(phone) {
        display: block;
      }
    }
  }

  &__pair {
    display: table-cell;

    @include media-range(phone) {
      display: block;
      margin-bottom: rem(5px);
    }

    &-icon {
      display: table-cell;
      padding-left: 0;
      padding-right: rem(20px);
      text-align: right;

      @include media-range(phone) {
        width: rem(60px);
        padding-left: rem(18px);
        padding-right: 0;
        padding-top: rem(3px);
        text-align: left;
      }

      .o-icon {
        width: em(20px);
        height: em(20px);
      }
    }

    &-desc {
      display: table-cell;
      padding-left: rem(20px);
      padding-right: 0;
      border-left: 1px solid color(black-haze);

      @include media-range(phone) {
        border-left: none;
        padding-left: rem(10px);
        line-height: 1.26;
      }
    }
  }

  .c-well__content {
    @include media-range(phone) {
      display: block;
    }
  }

  .c-pagination {
    margin-top: rem(70px);
  }
}
