// Functions
// =========
// General functions for projects

// Map-fetch
// ---------
// An easy way to fetch a deep value in a multi-level map.
// Works much like map-get() except that you pass multiple
// keys as the second parameter to go down multiple levels
// in the nested map.
// ---------
// - $map             : <map> target map
// - $keys            : <string> key from map
// ---------
// https://gist.github.com/jlong/8760275
// =========
@function map-fetch($map, $keys) {
  $key: nth($keys, 1);
  $length: length($keys);
  $value: map-get($map, $key);

  @if $length > 1 {
    $rest: ();

    @for $i from 2 through $length {
      $rest: append($rest, nth($keys, $i));
    }

    @return map-fetch($value, $rest);
  }

  @else {
    @return $value;
  }
}

// Color
// -----
// An easy way to get colors from the $colors map
// -----
// - $color           : <string> color name
// - $map             : <map> colors map
@function color($color, $map: $colors) {
  @return map-get($map, $color);
}

// Color-theme
// -----
// An easy way to get colors from the $theme map
// -----
// - $color           : <string> color name
// - $map             : <map> colors map
@function color-theme($target, $map: $colors-variant) {
  @return map-fetch($map, $target);
}

// Z-index
// -------
// An easy way to get z-index from the $z-index map
// -------
// - $color           : <string> color name
// - $map             : <map> colors map
@function z-index($element, $map: $z-index) {
  @return map-get($map, $element);
}

// Font Family
// -----------
// An easy way to get font family defined by project
// -----------
//
@function font-family($target, $map: $font-families) {
  @return map-fetch($map, $target);
}

// Font weight
// -----------
// An easy way to get font weight defined by project
// -----------
//
@function font-weight($target, $map: $font-weights) {
  @return map-fetch($font-weights, $target);
}

// Rem [Function]
// --------------
// Converts pixels to rem with $font-size-base
// or any other font size
// --------------
// - $target          : <font-size> font-size in pixels
// - $context         : <font-size> current root font-size
// --------------
// - https://gist.github.com/ijy/1441967
@function rem($target, $context: map-get($font-sizes, 'base')) {
  @if $target == 0 {
    @return 0;
  }

  @if $context == sm {
    $context: map-get($font-sizes, 'sm');
  }

  @return ($target / $context) + 0rem; // stylelint-disable length-zero-no-unit
}

// Em [Function]
// --------------
// Converts pixels to em with $font-size-base
// or any other font size
// --------------
// - $target          : <font-size> font-size in pixels
// - $context         : <font-size> current root font-size
// --------------
// - http://vvv.tobiassjosten.net/css/px-to-em-with-sass/
@function em($target, $context: map-get($font-sizes, 'base')) {
  @if $target == 0 {
    @return 0; // sass-lint:disable zero-unit
  }

  @if $context == sm {
    $context: map-get($font-sizes, 'sm');
  }

  @return ($target / $context) * 1em;
}

// Request theme color level [Function]
// --------------
// Generate different type of selected color
// --------------
// - $color           : <string> color
// - $level           : <number> color level
// --------------
@function theme-color-level($color, $level: 0) {
  $color-base: if($level > 0, #000, #fff);

  @if $level < 0 {

    // Lighter values need a quick double negative for the Sass math to work
    @return mix($color-base, $color, $level * -1 * $theme-color-interval);
  }

  @else {
    @return mix($color-base, $color, $level * $theme-color-interval);
  }
}
