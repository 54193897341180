// Card
// ====
// Simple card box
.c-card {
  $b: &; // set block context
  $padding: 30px;
  $padding-xs: 15px;
  $padding-sm: 15px;

  position: relative;
  display: block;
  width: 100%;
  padding: em($padding) em($padding) em($padding*1.5) em($padding);
  background: color(white);
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.04);

  @include media-range(phone) {
    padding: em($padding-xs) em($padding-xs) em($padding-xs*1.5) em($padding-xs);
  }

  @include media-range(tablet) {
    padding: em($padding-sm, sm) em($padding-sm, sm) em($padding-sm*1.5, sm) em($padding-sm, sm);
  }

  &__img {
    position: relative;
    margin: em(- $padding) em(- $padding) 0 em(- $padding);

    @include media-range(mobile) {
      margin: em(- $padding-xs) em(- $padding-xs) 0 em(- $padding-xs);
    }

    @include media-range(tablet) {
      margin: em(- $padding-sm, sm) em(- $padding-sm, sm) 0 em(- $padding-sm, sm);
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  &__img-bg {
    position: relative;
    margin: em(- $padding) em(- $padding) 0 em(- $padding);
    background-position: center;
    background-size: cover;

    &::after {
      content: '';
      display: block;
      padding-bottom: 52%;
    }

    @include media-range(mobile) {
      margin: em(- $padding-xs) em(- $padding-xs) 0 em(- $padding-xs);
    }

    @include media-range(tablet) {
      margin: em(- $padding-sm, sm) em(- $padding-sm, sm) 0 em(- $padding-sm, sm);
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &::after {
      content: '';
      display: block;
      padding-bottom: 52%;
    }

    .o-icon {
      width: em(45px);
      height: em(45px);
      filter: drop-shadow(0 0 27px rgba(0, 0, 0, 0.35));
      fill: #fff;

      @include transform-center;
    }

    img {
      @include transform-center;

      max-width: 50px;
    }
  }

  &__title {
    font-size: em(22px);
    margin-top: em(20px, 22px);
    font-family: font-family(secondary);

    @include media-range(phone) {
      font-size: em(18px);
    }

    @include media-range(tablet) {
      font-size: em(15px, sm);
    }
  }

  &__title-small {
    font-size: em(15px);
    margin-top: em(20px, 22px);
    font-weight: font-weight((primary, semi-bold));
  }

  &__desc {
    @include media-range(tablet) {
      font-size: em(12px, sm);
    }

    @include media-query(xs) {
      margin-bottom: rem(25px);
    }
  }

  &__title-small,
  &__title,
  &__desc {
    color: color(mine-shaft);

    &::before {
      content: " ";
      display: block;
      clear: both;
    }
  }

  &__tag {
    position: relative;
    display: block;
    max-width: 58%;
    margin-top: rem(20px);
    margin-bottom: em(20px, 22px);
    text-transform: uppercase;
    color: color(blue-stone);
    text-align: right;
    float: right;

    @include media-range(phone) {
      max-width: 70%;
    }
  }

  &__movie {
    .c-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__desc {
        margin-bottom: 1em;
      }

      &__flex  {
        width: 100%;
      }

      &__bottom {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        margin-bottom: 1em;

        @media screen and (min-width: 1025px) and (max-width: 1114px) {
          margin-bottom: 2em;
        }

        p {
          font-size: em(11px);
          color: color(mine-shaft);
          margin-bottom: 0;
        }

        > p {
          width: 100%;
        }
      }
    }
  }

  .c-ribbon {
    margin: rem(20px) 0 0 rem(- $padding);

    & + #{$b}__title {
      margin-top: rem(15px);
    }

    @include media-range(mobile) {
      font-size: 11px;
    }

    @include media-range(phone) {
      margin-left: rem(- $padding-xs);
    }

    @include media-range(tablet) {
      margin-left: rem(- $padding-sm, sm);
    }
  }

  .o-link {
    position: absolute;
    right: em($padding);
    bottom: em($padding);
    text-align: right;

    @include media-range(mobile) {
      bottom: em($padding-xs);
    }

    @include media-range(tablet) {
      bottom: em($padding-sm, sm);
    }
  }

  &__badges {
    position: absolute;
    display: table;
    width: 100%;
    left: em(-20px);
    top: em(-15px);
    font-size: 14px;
    z-index: 2;

    @include media-range(mobile) {
      font-size: 11px;
    }

    .c-ribbon-level {
      font-weight: normal;
    }
  }

  &.news-practician {
    padding: 0 0 em($padding*1.5) 0;

    .c-card {
      &__title {
        height: 5em;
        color: color(white);
        background-color: color-theme((t-green, primary));
        vertical-align: middle;
        text-align: center;
        padding: em($padding/2);
        margin-top: 0;
      }

      &__desc {
        padding: 0 em($padding)
      }


      &__bottomauthor {
        position: absolute;
        padding: 0 em($padding);
        bottom: em($padding*2.5);
        color: color(mine-shaft);
        height: em($padding*1.5);
        overflow: hidden;

        &::before {
          content: " ";
          display: block;
          clear: both;
        }
      }

      &__authorspace {
        height: em($padding*3);
      }

      .o-link {
        height: em($padding);
      }

    }
  }
}
