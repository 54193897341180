// Form row
// ========
// Group of label + input
.o-form-row {
  $b: &;

  margin-bottom: rem(30px);

  // clear margin for nested row
  > .o-form-row {
    @include media-query(sm) {
      margin-bottom: 0;
    }
  }

  &--space-top {
    margin-top: rem(60px);

    @include media-range(phone) {
      margin-top: rem(30px);
    }
  }

  &--space-between {
    .o-form-row {
      &:nth-of-type(odd) {
        @include media-query(md) {
          padding-right: rem(50px);
        }
      }

      &:nth-of-type(even) {
        @include media-query(md) {
          padding-left: rem(50px);
        }
      }
    }
  }

  &--space-bottom-0 {
    margin-bottom: 0;
  }

  &--equal {
    @include media-query(sm) {
      display: flex;

      > #{$b} {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &--hidden {
    &:not(.has-error) {
      margin-bottom: 0;
    }
  }

  .o-label,
  .o-input {
    display: block;
    width: 100%;
  }

  .o-label {
    margin-bottom: em(10px);

    #{$b}--equal & {
      flex: 1 1 auto;
    }
  }

  .o-input {

    // give space for whatever element below input
    & + * {
      margin-top: em(5px);
    }
  }

  &.form-group-error {
    input.o-input {
      border-color: red;
    }
    .form-group-error-container {

      ul {
        margin: 0;
        list-style-type: none;
        padding-left: em(15px);

        > li {
          text-indent: -5px;
          font-size: small;
          color: red;

          &:before {
            content: "-";
            text-indent: -5px;
          }
        }
      }
    }
  }
}
