// Block switch
// ============
// Modules block on homepage
.c-block-switch {
  $ctx: 18px; // set font-size context
  $gutter-tablet: 10px;
  $gutter-phone: 7px;

  position: relative;

  @include media-query(md) {
    //margin-top: rem(-55px);
    //margin-bottom: rem(25px);
  }

  &__list {
    @include list-unstyle;

    display: flex;
    flex-wrap: wrap;

    @include media-query(md) {
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    @include media-range(tablet) {
      margin: 0 rem(-5px);
    }

    @include media-range(phone) {
      @include grid-make-default-row();
    }
  }

  &__item {
    flex: 1 1 0;
    margin-right: em(15px, $ctx);
    font-size: em($ctx);
    background: color(white);
    color: color(white);
    border-bottom: 5px solid color-theme((t-blue, primary));
    box-shadow: 5px 5px 13px 0 rgba(0, 0, 0, 0.08);

    &:hover {
      color: color(white);
      background-color: color-theme((t-blue, primary));

      a {
        color: color(white);
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    @include media-query(md) {
      align-self: flex-end;
    }

    @include media-range(tablet) {
      border-bottom-width: 7px;
      margin: 0 rem(5px) rem(10px);
      flex: 0 1 calc(33.333% - #{rem(10px)}); // set 3 items in row minus margin of both side
    }

    @include media-range(phone) {
      font-size: 0.9em;
      margin: 0 rem(7.5px) rem(14px) rem(7.5px);
      flex: 0 1 calc(50% - #{rem(15px)});
    }

    &--blue {
      border-color: color-theme((t-blue, primary));

      &:hover {
        background-color: color-theme((t-blue, primary));
      }
    }

    &--green {
      border-color: color-theme((t-green, primary));

      &:hover {
        background-color: color-theme((t-green, primary));
      }
    }

    &--purple {
      border-color: color-theme((t-purple, primary));

      &:hover {
        background-color: color-theme((t-purple, primary));
      }
    }

    &--orange {
      border-color: color-theme((t-orange, primary));

      &:hover {
        background-color: color-theme((t-orange, primary));
      }
    }

    &--marine {
      border-color: color-theme((t-marine, primary));

      &:hover {
        background-color: color-theme((t-marine, primary));
      }
    }

    &--yellow {
      border-color: color-theme((t-yellow, primary));

      &:hover {
        background-color: color-theme((t-yellow, primary));
      }
    }

    &--pink {
      border-color: color-theme((t-pink, primary));

      &:hover {
        background-color: color-theme((t-pink, primary));
      }
    }
  }

  &__link {
    display: flex;
    height: 100%;

    &:hover {
      color: color(white);
    }

    @include media-query(md) {
      align-items: center;
    }
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include media-query(md) {
      min-height: rem(80px);
    }

    @include media-range(mobile) {
      align-content: flex-start;
    }
  }

  &__img {
    width: 100%;
    height: rem(80px, $ctx);
    background-size: cover;
    background-position: center;

    @include media-range(tablet) {
      height: rem(110px, $ctx);
    }

    @include media-query(md) {
      display: none;
    }
  }

  &__desc {
    width: 100%;
    padding: em(12px, $ctx) em(20px, $ctx);
    align-self: center;

    @include media-range(tablet) {
      padding: em(17px, $ctx) em(25px, $ctx);
    }
  }

  &__label {
    margin: 0;
    color: currentColor;
    font-family: font-family(secondary);

    @include media-range(tablet) {
      font-size: rem(22px, sm);
    }
  }

  &__teaser {
    height: 0;
    overflow: hidden;
    font-size: em(13px, $ctx);

    @include media-range(mobile) {
      display: none;
    }

    p {
      padding-top: em(20px, $ctx);
    }
  }
}
