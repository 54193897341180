// Select
// ====
// Basic selectbox
.o-select {
  $background: map-get($colors-form, input-bg);
  $border-color: map-get($colors-form, input-border);
  $paddings-base: map-get($select-paddings, base);
  $font-size-base: map-get($input-font-sizes, base);
  $line-height-base: map-get($input-line-heights, base);

  width: 100%;
  padding: em(map-get($paddings-base, vertical)) em(60px, $font-size-base) em(map-get($paddings-base, vertical)) em(map-get($paddings-base, horizontal));
  border: 1px solid $border-color;
  border-radius: 0;
  font-size: $font-size-base;
  line-height: $line-height-base;
  background-color: $background;
  color: color(primary, $colors-brand);
  appearance: none;

  // set colors for theme variants
  @include themify() {
    color: theme(primary);
  }

  &--small {
    $paddings-small: map-get($select-paddings, small);

    padding-left: em(map-get($paddings-small, horizontal));
    padding-right: em(map-get($paddings-small, horizontal));
  }

  & + .o-alert {
    margin-top: em(5px);
  }
}

.o-select-f3-form-error {
  .o-select {
    background-color: #FF9F9F;
    border: 1px #FF0000 solid;
    color: black;
  }
}
