// Colors
// ======
// Colors classes

$u-colors-block: (
  yellow: map-get($colors, 'my-sin'),
  pistachio: map-get($colors, 'pistachio'),
  marine: map-get($colors, 'mountain-meadow'),
  blue: map-get($colors, 'deep-cerulean'),
  purple: map-get($colors, 'flirt'),
  gray: map-get($colors, 'porcelain'),
  orange: map-get($colors, 'mango-tango'),
  black-squeeze: map-get($colors, 'black-squeeze'),
  blue-stone: map-get($colors, 'blue-stone'),
  green: map-get($colors, 'fun-green'),
  white: #fff,
  transparent: transparent
);

// background colors
.u-bg {
  @each $color in $u-colors-block {
    $key: nth($color, 1);
    $val: nth($color, 2);

    &-#{$key} {
      background-color: $val !important;
    }
  }
}

//accordion header color
.c-accordion-bg {
  @each $color in $u-colors-block {
    $key: nth($color, 1);
    $val: nth($color, 2);

    &-#{$key} {
      .c-accordion__header,
      .c-accordion__header.is-open {
        background-color: $val !important;

        .c-accordion__title,
        .c-accordion__addon,
        .o-icon {
          color: white;
        }

        & + .c-accordion__desc .c-accordion__wrapper {
          background-color: transparent;
        }
      }
    }
  }
}

.l-section.audio-container {
  .c-accordion-bg {
    @each $color in $u-colors-block {
      $key: nth($color, 1);
      $val: nth($color, 2);

      &-#{$key} {
        .c-accordion__header,
        .c-accordion__header.is-open {
          & + .c-accordion__desc .c-accordion__wrapper {
            background-color: $val !important;
          }
        }
      }
    }
  }
}

// text colors
.u-color {
  @each $color in $u-colors-block {
    $key: nth($color, 1);
    $val: nth($color, 2);

    &-#{$key} {
      color: $val !important;
    }
  }
}
