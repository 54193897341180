// Bg-img
// ======
// Background image in content
.c-bg-img {
  position: absolute;
  height: 100%;
  width: 100%;
  background-position: right center;
  background-size: contain;

  &--left {
    background-position: left center;
  }

  // pull height of background element
  // @usage layouts/_section.scss
  .l-section & {
    @include media-query(md) {
      $space-md: map-get(map-fetch($space-base, md), medium);

      height: calc(100% + #{rem($space-md)} * 2);
      transform: translateY(rem(-$space-md));
    }

    @include media-range(tablet) {
      $space-sm: map-get(map-fetch($space-base, sm), medium);

      height: calc(100% + #{rem($space-sm)} * 2);
      transform: translateY(rem(-$space-sm));
    }
  }

  // define width of background element
  // when is located in 2 col layout
  // @usage layouts/_2col.scss
  .l-2col & {
    @include media-query(sm) {
      width: auto;
      left: 0;
      right: 0;
    }

    @include media-query(md) {
      right: rem($l-aside) + gutter($susy-fixed) * 2;
    }
  }
}
