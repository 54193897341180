// Typo3
// =====
// Typo3 content elements
.ce-align-left {
  text-align: left;
}

.ce-align-center {
  text-align: center;
}

.ce-align-right {
  text-align: right;
}

.ce-table td,
.ce-table th {
  vertical-align: top;
}

.ce-textpic,
.ce-image,
.ce-nowrap,
.ce-bodytext,
.ce-gallery,
.ce-row,
.ce-uploads li,
.ce-uploads div {
  overflow: hidden;
}

.ce-row {
  margin-left: rem(-10px);
  margin-right: rem(-10px);

  @include susy-clearfix;

  .ce-column {
    padding-left: rem(10px);
    padding-right: rem(10px);
    margin-bottom: rem(20px);
  }

  iframe {
    max-width: 100%;
  }
}

.ce-column {
  @include media-query(sm) {
    float: left;
  }
}

.ce-center .ce-gallery:not(.c-gallery) .ce-outer {
  position: relative;
  float: right;
  right: 50%;
}

.ce-center .ce-gallery:not(.c-gallery) .ce-inner {
  position: relative;
  float: right;
  right: -50%;
}

.ce-gallery {
  figure {
    display: table;
    margin: 0;
  }

  figcaption {
    display: table-caption;
    caption-side: bottom;
  }

  img {
    display: block;
  }

  iframe {
    border-width: 0;
  }

  .ce-right & {
    @include media-query(sm) {
      float: right;
    }
  }

  .ce-left & {
    @include media-query(sm) {
      float: left;
    }

    @include media-range(phone) {
      margin-bottom: em(20px);
    }
  }
}

.ce-column:last-child {
  margin-right: 0;
}

.ce-row:last-child {
  margin-bottom: 0;
}

.ce-above .ce-bodytext {
  clear: both;
  padding-top: 1em;
}

.ce-intext.ce-left ol,
.ce-intext.ce-left ul {
  padding-left: 40px;
  overflow: auto;
}

.ce-intext {
  // in text position - enable overflow
  &:not(.ce-nowrap),
  &:not(.ce-nowrap) .ce-bodytext {
    overflow: visible;
  }

  &.ce-nowrap {
    p:first-of-type {
      margin-top: 0;
    }
  }

  // set margin for images in text
  &.ce-right {
    .ce-gallery {
      @include media-query(sm) {
        margin-left: 2em;
      }
    }
  }

  &.ce-left {
    .ce-gallery {
      @include media-query(sm) {
        margin-right: 2em;
      }

      @include media-range(phone) {
        margin-bottom: 2em;
      }
    }
  }

  &.ce-left,
  &.ce-right {
    .ce-bodytext {
      @include media-range(phone) {
        margin-top: 2em;
      }
    }
  }

  img {
    @include media-range(phone) {
      width: 100%;
    }
  }

  .c-gallery__item.width-normalize {
    @include media-range(phone) {
      width: unset;
      margin: auto;
    }
  }

  figure {
    @include media-range(phone) {
      display: block;
    }
  }
}

// Bullets
.ce-bullets {
  list-style-position: inside;

  li,
  ol,
  dt,
  dd {
    margin-bottom: 1.6em;
  }
}

// Headline
.ce-headline-left {
  text-align: left;
}

.ce-headline-center {
  text-align: center;
}

.ce-headline-right {
  text-align: right;
}

// Uploads
.ce-uploads {
  margin: 0;
  padding: 0;
}

.ce-uploads li {
  list-style: none outside none;
  margin: 1em 0;
}

.ce-uploads img {
  float: left;
  padding-right: 1em;
  vertical-align: top;
}

.ce-uploads span {
  display: block;
}

// Table
.ce-table {
  width: 100%;
  max-width: 100%;
}

.ce-table th,
.ce-table td {
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.ce-table thead th {
  border-bottom: 2px solid #dadada;
}

.ce-table th,
.ce-table td {
  border-top: 1px solid #dadada;
}

.ce-table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.ce-table-bordered th,
.ce-table-bordered td {
  border: 1px solid #dadada;
}

// Space
.frame-space-before-extra-small {
  margin-top: 1em;
}

.frame-space-before-small {
  margin-top: 2em;
}

.frame-space-before-medium {
  margin-top: 3em;
}

.frame-space-before-large {
  margin-top: 4em;
}

.frame-space-before-extra-large {
  margin-top: 5em;
}

.frame-space-after-extra-small {
  margin-bottom: 1em;
}

.frame-space-after-small {
  margin-bottom: 2em;
}

.frame-space-after-medium {
  margin-bottom: 3em;
}

.frame-space-after-large {
  margin-bottom: 4em;
}

.frame-space-after-extra-large {
  margin-bottom: 5em;
}

// Frame
.frame-ruler-before::before {
  content: '';
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 2em;
}

.frame-ruler-after::after {
  content: '';
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 2em;
}

.frame-indent {
  margin-left: 15%;
  margin-right: 15%;
}

.frame-indent-left {
  margin-left: 33%;
}

.frame-indent-right {
  margin-right: 33%;
}

// Frame half
// ----------
// 50-50 columns
.frame-half {
  .ce-textpic {
    > div {
      @include media-query(sm) {
        width: 50%;
      }
    }
  }

  .ce-left {
    .ce-gallery {
      @include media-query(md) {
        padding-right: em(50px);
      }

      @include media-range(tablet) {
        padding-right: em(25px);
      }
    }

    .ce-bodytext {
      @include media-query(md) {
        padding-left: em(50px);
      }

      @include media-range(tablet) {
        padding-left: em(25px);
      }
    }
  }

  .ce-right {
    .ce-gallery {
      @include media-query(md) {
        padding-left: em(50px);
      }

      @include media-range(tablet) {
        padding-left: em(25px);
      }
    }

    .ce-bodytext {
      @include media-query(md) {
        padding-right: em(50px);
      }

      @include media-range(tablet) {
        padding-right: em(25px);
      }
    }
  }

  .ce-gallery {
    img {
      width: 100%;
    }
  }

  .ce-intext .ce-gallery {
    margin: 0;
  }
}

.frame {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

// Content from CKE
// ================
.ce-bodytext {
  &.mini-content {
    width: 60%;

    @include media-range(phone) {
      width: 100%;
    }
  }
  a:not([class]) {
    color: color(deep-cerulean);

    @include on-event() {
      color: map-get($colors-link, hover);
    }
  }
}
