// Z-index
// =======
// Indexes for elements
//
// stylelint-disable indentation

$z-index: (
        header: 100,
        dropdown: 101,
        nav-link: 102,
        nav-mobile: 200,
        backdrop: 1000,
        cookie: 998,
) !default;
