// Colors
// ======
// For project colors.
//
// Approach:
// ---------
// @LandonSchropp's approach of naming color variables.
// - http://davidwalsh.name/sass-color-variables-dont-suck
// - http://chir.ag/projects/name-that-color/
//
// Usage:
// ------
// .test {
//   background-color: color(shamrock);
// }
//
// stylelint-disable indentation

$colors: (
        white: #fff,
        black: #000,
        prussian-blue: #003057,
        blue-stone: #005a70,
        deep-cerulean: #007fa3,
        mountain-meadow: #12b2a6,
        morning-glory: #a9d5e0,
        bitter-lemon: #d2db0e,
        my-sin: #ffb81c,
        mango-tango: #ea7600,
        flirt: #9e007e,
        rose: #ea067e,
        monza: #db0020,
        fun-green: #008638,
        pistachio: #84bd00,
        skeptic: #d4eae4,
        geyser: #d6dee2,
        mine-shaft: #3d3d3d,
        abbey: #505759,
        rolling-stone: #747c80,
        gray-chateau: #a4abaf,
        iron: #d3d4d4,
        mercury: #e7e7e7,
        black-haze: #eff0f0,
        athens-gray: #f4f4f5,
        slate-gray: #698187,
        alabaster: #f8f8f8,
        porcelain: #f4f6f7,
        silver: #ccc,
        black-squeeze: #e8f1f6,
        heather: #b7c8d1,
        catskill-white: #e7f0f5,
        outrageous-orange: #fa5a3c
);

$colors-brand: (
        primary: map-get($colors, 'blue-stone'),
        secondary: map-get($colors, 'mango-tango'),
);

$colors-link: (
        normal: map-get($colors, 'prussian-blue'),
        hover: map-get($colors, 'mango-tango'),
);

$colors-typo: (
        primary: map-get($colors, 'black')
);

$colors-form: (
        input-bg:     map-get($colors, white),
        input-border: map-get($colors, silver),
        input-color:  map-get($colors, black)
);

$colors-alerts: (
        danger: #dc3545,
        success: #28a745,
        warning: map-get($colors, 'mango-tango')
);

// Themes
// ------
$colors-variant: (
        t-blue: (
                primary: map-get($colors, blue-stone)
        ),
        t-green: (
                primary: map-get($colors, fun-green)
        ),
        t-purple: (
                primary: map-get($colors, flirt)
        ),
        t-orange: (
                primary: map-get($colors, mango-tango)
        ),
        t-marine: (
                primary: map-get($colors, mountain-meadow)
        ),
        t-yellow: (
                primary: map-get($colors, my-sin)
        ),
        t-pink: (
                primary: map-get($colors, rose)
        )
);

$themes: (
        t-blue: map-get($colors-variant, t-blue),
        t-green: map-get($colors-variant, t-green)
);

$theme-color-interval: 8% !default;
