// List search
// ==============
.c-list-search {
  $b: &;

  margin-bottom: rem(40px);

  &__list {
    margin-top: rem(30px);
  }

  &__addon {
    display: table-cell;
    width: rem(332px);
    max-width: 100%;
    vertical-align: top;
    text-align: center;

    @include media-range(phone) {
      display: block;
      margin-bottom: rem(5px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__img {
    position: relative;
    display: inline-block;
    height: rem(160px);

    &-badge {
      position: absolute;
      left: rem(-55px);
      top: rem(35px);
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__wider {
    margin: 0 0 0 em(-25px);

    @include media-range(phone) {
      margin-left: em(-15px);
      margin-right: em(-15px);
    }
  }

  &__item {
    position: relative;
    display: block;
    margin-bottom: rem(20px);
    padding-top: rem(35px);
    padding-bottom: rem(35px);

    @include media-range(phone) {
      padding-bottom: rem(25px);
      padding-top: rem(25px);
    }

    &:hover {
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
    }

    .c-well__addon {
      text-align: center;

      @include media-range(phone) {
        display: block;
        width: auto;
        margin-left: rem(-15px);
        margin-right: rem(-15px);
      }

      @include media-range(medium) {
        display: block;
        width: 100%;
      }
    }

    .o-btn {
      display: block;
      padding-right: rem(22px);
      padding-left: rem(22px);

      @include media-range(medium) {
        display: inline-block;
        width: 50%;
      }

      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
  }

  &__desc {
    padding-bottom: rem(15px);

    @include media-range(phone) {
      line-height: 1.3;
    }
  }

  &__content {
    display: table;
    width: 100%;
    padding-top: rem(10px);
    padding-left: rem(10px);

    @include media-range(phone) {
      display: block;
      padding-right: 0;
      padding-left: 0;
    }

    @include media-range(medium) {
      display: block;
      padding-right: 0;
      margin-bottom: rem(13px);
    }
  }

  &__title {
    margin-bottom: rem(15px);

    @include media-range(phone) {
      margin-bottom: rem(15px);
      font-size: rem(18px);
    }

    &-wsub {
      margin-bottom: 0;
      @include media-range(phone) {
        font-size: rem(18px);
      }
    }

    &.tests {
      p:first-of-type {
        font-weight: bold;
        margin: 0;
      }

      p.segment {
        font-size: 0.75rem;
        top: 0;
      }
    }

    &,
    a:not(:hover) {
      color: color(blue-stone);
    }
  }

  &__subtitle {
    margin-bottom: rem(5px);
    font-size: rem(10px);
    margin-left: rem(2px);

    &,
    a:not(:hover) {
      color: color(blue-stone);
    }
  }

  .c-well__content {
    @include media-range(phone) {
      display: block;
    }
  }

  .o-link {
    position: absolute;
    right: rem(25px);
    bottom: rem(20px);

    @include media-range(phone) {
      right: rem(15px);
      bottom: rem(15px);
    }
  }

  .c-tags {
    margin-bottom: 0;
  }

  .c-list-checkbox__btn {
    @include media-range(phone) {
      margin-top: 0;
    }
  }

  &--test {
    #{$b}__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: rem(-10px);
      margin-right: em(-10px);
    }

    #{$b}__addon {
      width: 40%;
      padding-left: em(25px);
      padding-right: em(25px);

      @include media-range(phone) {
        width: 100%;
      }
    }

    #{$b}__content {
      position: relative;
      padding-left: em(25px);
      padding-bottom: em(50px);
      height: 100%;

      @include media-range(phone) {
        padding-left: 0;
      }
    }

    #{$b}__item-wrapper {
      width: 50%;
      margin-bottom: em(20px);
      padding: 0 em(10px);

      @include media-range(mobile) {
        width: 100%;
      }
    }

    #{$b}__item {
      height: 100%;
      margin: 0;
      padding: em(50px) em(35px) em(50px) em(10px);
      background-color: color(porcelain);

      @include media-range(phone) {
        height: auto;
        padding-right: em(10px);
      }

      #{$b}__title {
        display: block;

        &,
        * {
          font-family: font-family(primary);
          font-size: rem(20px);
        }
      }

      .o-btn {
        position: absolute;
        bottom: 0;
        display: inline-block;
        width: auto;
        padding-left: rem(28px);
        padding-right: rem(50px);

        @include media-range(phone) {
          padding-right: rem(28px);
        }

        .o-link {
          &__icon {
            @include media-range(phone) {
              margin-left: em(15px);
            }
          }
        }
      }

      .c-well__content {
        height: 100%;
      }
    }
  }
}
