// Form Control
// ======

.u-form-control {

  &.validation-error {
    color: #FF9F9F;
  }

}
