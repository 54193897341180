// Consultants
// =========
.c-consultants {
  &__header {
    margin-bottom: rem(20px);
  }

  &__title {
    margin-right: rem(30px);
  }

  .o-select-wrapper {
    width: (100% / 3);

    @include media-range(phone) {
      width: 100%;
    }
  }
}

// Consultants
// =========
.c-consultant {
  display: table;
  width: 100%;
  margin-bottom: rem(30px);
  padding-top: rem(15px);

  &__img {
    display: table-cell;
    width: rem(115px);
    vertical-align: top;

    @include media-range(phone) {
      display: block;
      margin-bottom: rem(10px);
    }

    img {
      width: 100%;
      height: rem(115px);
      border-radius: 50%;
      border: 3px solid color(deep-cerulean);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }

  &__desc {
    display: table-cell;
    vertical-align: top;
    padding: 0 rem(35px) rem(35px);

    @include media-range(phone) {
      display: block;
      width: 100%;
      padding: 0;
    }
  }

  &__signature {
    display: table-cell;
    height: rem(115px);
    vertical-align: middle;

    @include media-range(phone) {
      display: block;
      height: auto;
      margin-bottom: rem(20px);
    }
  }

  &__name {
    display: block;
    color: color(deep-cerulean);
    font-size: rem(18px);
  }

  &__subtitle {
    display: block;
  }

  &__contact {
    margin-top: rem(5px);
    margin-bottom: rem(40px);

    @include media-range(phone) {
      margin-top: 0;
      margin-bottom: rem(20px);
      padding-left: rem(10px);
    }

    .u-input-group-btn {
      padding-top: rem(5px);
      padding-right: rem(10px);
    }

    .o-icon {
      fill: color(deep-cerulean);
    }
  }
}
