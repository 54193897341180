// Grid
// =====
// Grid for user ( RTE / CONTENT )
.c-grid {
  overflow: hidden;

  .row {
    @include media-query(sm) {
      margin-left: rem(-30px);
      margin-right: rem(-30px);
    }
  }

  [class^="col-"],
  [class*=" col-"] {
    @include media-query(sm) {
      padding-left: rem(30px);
      padding-right: rem(30px);
    }
  }
}
