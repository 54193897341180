// Selectric
// ========
// Selectbox plugin
// @extend objects/_select.scss

$select-background: map-get($colors-form, input-bg);
$select-border-color: map-get($colors-form, input-border);
$select-paddings-base: map-get($select-paddings, base);
$select-paddings-small: map-get($select-paddings, small);
$select-font-size-base: map-get($input-font-sizes, base);
$select-line-height-base: map-get($input-line-heights, base);

$selectric-main-color:      #ddd !default;                                              // Color used in border
$selectric-secondary-color: #bbb !default;                                              // Color used in button
$selectric-text-color:      #444 !default;                                              // Color used in label
$selectric-bg-color:        #fff !default;                                           // Background color
$selectric-height:          40px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height

.o-select-wrapper {
  position: relative;
  cursor: pointer;
}

.o-select-responsive {
  width: 100%;
}

.o-select {
  position: relative;

  .o-select-o-select--small & {
    padding-left: em(map-get($select-paddings-small, horizontal));
    padding-right: em(map-get($select-paddings-small, horizontal));
  }

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;

    @include media-range(phone) {
      white-space: normal;
    }
  }

  &__arrow {
    display: block;
    position: absolute;
    right: em(map-get($select-paddings-base, horizontal));
    top: 0;
    bottom: 0;
    height: rem(20px);
    margin: auto;
    width: rem(20px);
    text-align: center;
    transform: rotate(90deg);
    transition: transform 0.2s ease;

    .o-select-o-select--small & {
      right: em(map-get($select-paddings-small, horizontal));
    }

    .o-icon {
      width: rem(20px);
      height: rem(20px);
    }
  }
}

.o-select-focus .o-select {
  border-color: darken($selectric-main-color, 20%);
}

.o-select-hover .o-select {
  border-color: darken($selectric-main-color, 10%);

  .button {
    color: darken($selectric-secondary-color, 10%);

    &::after {
      border-top-color: darken($selectric-secondary-color, 10%);
    }
  }
}

.o-select-open {
  z-index: 9999;

  .o-select {
    border-color: darken($selectric-main-color, 10%);
  }

  .o-select-items {
    display: block;
  }

  .o-select__arrow {
    transform: rotate(270deg);
  }
}

.o-select-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.o-select-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.o-select-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.o-select-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.o-select-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.o-select-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $selectric-bg-color;
  border: 1px solid $select-border-color;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  white-space: normal;
  //width: auto !important;

  .o-select-scroll {
    height: 100%;
    overflow: auto;
  }

  .o-select-above & {
    top: auto;
    bottom: 100%;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 20px;
  }

  li {
    display: block;
    color: #666;
    cursor: pointer;
    padding: em(map-get($select-paddings-base, vertical)) em(map-get($select-paddings-base, horizontal));
    line-height: $select-line-height-base;

    &:nth-of-type(odd) {
      background-color: color(porcelain);
    }

    &.selected {
      background: #e0e0e0;
      color: #444;
    }

    &.highlighted,
    &:hover {
      background: color(primary, $colors-brand);
      color: color(white);

      // set colors for theme variants
      @include themify() {
        background: theme(primary);
      }
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .o-select-group {
    .o-select-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}
