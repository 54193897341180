// Product listing
// ===============
.#{$m}-list {
  &__title {
    margin-bottom: rem(40px);

    @include media-range(phone) {
      margin-bottom: rem(25px);
    }
  }

  .l-grid {
    padding-top: rem(15px);

    &__item {
      margin-bottom: rem(40px);
    }
  }
}
