// Reset
// =====
// Our reset rules

// reset browsers styles
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input,
button {
  background: none;
  border: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  line-height: initial;
  overflow: visible;
  border-radius: 0;

  &,
  &[type="search"] {
    -webkit-appearance: none;
  }
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}
