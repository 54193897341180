// Thumb
// =====
.c-thumb {
  $b: &;

  &__img {
    width: 100%;
  }

  &__caption {
    margin-top: rem(15px);
    word-break: break-word;
  }

  //thumb inside <a> with o-link__icon
  &__icon {
    width: 100%;
    text-align: right;
  }

  &__logo {
    @include transform-center;

    width: auto;
    max-width: calc(100% - #{rem(20px)});
    max-height: calc(100% - #{rem(20px)});

    @include media-query(sm) {
      max-height: calc(70% - #{rem(20px)});
    }
  }

  &__rect {
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-bottom: 46%;
    }
  }

  &__video {
    position: relative;

    .o-icon-play {
      max-width: em(45px);

      @include transform-center;
    }
  }

  &--wide {
    #{$b}__logo {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--link {
    font-size: em(20px);
    font-family: font-family(secondary);

    @include media-range(tablet) {
      font-size: em(14px, sm);
    }

    @include media-range(phone) {
      font-size: em(12px);
    }

    #{$b}__caption {
      min-height: em(62px, 20px);

      @include media-range(mobile) {
        margin-top: rem(6px);
        min-height: em(38px, 12px);
      }
    }

    .o-link {
      font-size: rem(14px);

      @include media-range(mobile) {
        font-size: rem(10px);
      }

      &__icon {
        @include media-range(mobile) {
          border-width: 1px;
        }
      }
    }
  }

  &--link-inline {
    #{$b}__title {
      display: table;
      width: 100%;
    }

    .o-link {
      display: table-cell;
      width: 1%;
      white-space: nowrap;
    }
  }
}
