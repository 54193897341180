// Schools
// ====
// Edit user schools
.#{$m}-schools {
  &__row {
    display: flex;

    &:last-of-type {
      .o-form-row {
        margin-bottom: 0;
      }
    }
  }

  &__labels {
    .o-form-row {
      margin-bottom: 0;
    }
  }

  &__school {
    @include media-range(phone) {
      margin-top: rem(15px);
    }
    .multiselect__tags input{
      padding-right: rem(60px);
    }
  }

  &__addon {
    //align-self: center;
    margin-top: rem(10px);

    @include media-range(phone) {
      align-self: flex-end;
    }
  }

  &__add {
    display: block;
    width: rem(25px);
    height: rem(25px);
    color: color(blue-stone);
    cursor: pointer;

    &.school-book {
      margin-top: 0.7em;
    }

    @include media-range(phone) {
      margin-bottom: rem(5px);
    }

    &.x {
      @include media-range(phone) {
        margin-top: 0;
        margin-bottom: rem(35px);
      }

      .o-icon {
        transform: rotate(135deg);
      }
    }

    .o-icon {
      width: 100%;
      height: 100%;
      color: color(blue-stone);

      @include transition();
    }
  }

  > .o-form-row {
    margin-bottom: 0;
  }
}

.user-edit-school {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;

  @include media-range(phone) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
