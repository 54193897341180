// Stores
// ======
// Stores map
.c-stores {
  $b: &;

  &.is-loading,
  &.is-loading #{$b}__geo {
    cursor: wait;
  }

  &__bar {
    padding-bottom: rem(25px);
  }

  &__geo {
    display: block;
    padding-right: rem(60px);
    color: color(black);

    @include media-range(phone) {
      padding-bottom: rem(15px);
    }

    .o-icon {
      width: em(25px);
      height: em(25px);
      vertical-align: middle;
      color: #6e7476;

      & + span {
        display: inline-block;
        margin-left: rem(10px);
        vertical-align: middle;
      }
    }
  }

  &__input {
    position: relative;

    .o-input {
      width: 100%;
      padding-right: rem(35px);
    }
  }

  &__btn {
    @include transform-center($horizontal: false);

    position: absolute;
    right: rem(15px);

    .o-icon {
      width: em(12px);
      height: em(12px);
      vertical-align: 0;
    }
  }

  &__marker {
    max-width: rem(160px);
    padding: rem(6px);
    font-family: font-family(primary);
    font-weight: font-weight((primary, normal));

    p {
      margin-bottom: rem(4px);
      font-size: rem(14px);
    }

    .title {
      font-weight: font-weight((primary, semi-bold));
    }

    .o-btn {
      margin-top: rem(15px);
      white-space: nowrap;
    }
  }

  .c-header__addon {
    @include media-range(phone) {
      display: block;
    }
  }

  .gm-style .gm-style-iw {
    border-radius: 0;
  }

  &--select {
    #{$b}__search {
      display: table-cell;
      padding-right: rem(15px);

      @include media-range(phone) {
        display: block;
        margin-bottom: rem(15px);
        padding-right: 0;
      }
    }

    #{$b}__select {
      display: table-cell;

      @include media-range(phone) {
        display: block;
      }

      .o-select {
        @include media-range(tablet) {
          font-size: 13px;
        }
      }
    }
  }
}
