// Mixins
// ======
// General mixins for project

// Media-query alias for mappy-breakpoint [Mixin]
// ----------------
// - $queries         : <string> or <number> in the format:
//                      <min-width> <max-width> h <min-height> <max-height> <key> <value>
// - $type            : <media-type>
// - $query-fallback  : <string> selector class
// - $breakpoints     : <map>
@mixin media-query($queries, $type: all, $query-fallback: null, $breakpoints: $breakpoints) {
  @include mappy-bp($queries, $type, $query-fallback, $breakpoints) {
    @content;
  }
}

@mixin media-range($query, $mappy-queries: $breakpoints-queries) {
  @include mappy-query($query, $mappy-queries) {
    @content;
  }
}

@mixin media-range-or($queries, $mappy-queries: $breakpoints-queries) {
  @include mappy-query-or($queries, $mappy-queries) {
    @content;
  }
}

// ABS Center [Mixin]
// ----------------
// - $horizontal      : <boolean> true or false
// - $vertical        : <boolean> true or false
@mixin abs-center($horizontal: true, $vertical: true) {
  position: absolute;
  margin: auto;

  @if ($horizontal) {
    left: 0;
    right: 0;
  }

  @if ($vertical) {
    top: 0;
    bottom: 0;
  }
}

// Transform center [Mixin]
// ------------------------
// - $horizontal      : <boolean> true or false
// - $vertical        : <boolean> true or false
@mixin transform-center($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

// Transition [Mixin]
// -----------------
//
@mixin transition($property: all, $time: $animation-time, $function: $animation-function) {
  transition: $property $time $function;
}

// Unstyle list
// ------------
@mixin list-unstyle() {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

// Unstyle table
// -------------
@mixin table-unstyle() {
  tr {
    border: 0;

    &:nth-of-type(odd) {
      td {
        background: none;
      }
    }
  }

  td {
    padding: 0;
    background: none;
  }
}

// Event wrapper [Mixin]
// ---------------------
// - $self            : <boolean> or <string> Whether or not to include current selector or more selectors
// @see https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
// stylelint-disable at-rule-empty-line-before, block-closing-brace-newline-after, rule-empty-line-before
// Reason: @todo add @if @else styelint rule
@mixin on-event($self: false) {
  @if $self {
    #{$self},
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {

    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

// Alert
@import 'mixins/alert';
