.m-country {
  background-color: #fff;
  padding: 30px 30px 15px 30px;
  color: #003057;
  margin: 20px 0 40px 0;
  line-height: 1.625;

  &--landing-page {
    font-family: 'Open Sans', 'Helvetica', 'Verdana', sans-serif;
    margin: 0 20px;
    color: #333;
    line-height: 1.2;
  }

  &--main-header {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    display: block;
    margin-bottom: 10px;
    @include media-query(sm) {
      margin: 0 2.5%;
    }
  }

  &--select {
    width: 100%;
    padding: 0;
    display: inline-flex;
    border-bottom: 1px solid #dbdbdb;
    font-size: 1.1rem;
    font-weight: 400;
    list-style: none;
    @include media-query(sm) {
      width: 19%;
      margin: 0 2.5%;
      white-space: nowrap;
    }

    &:nth-child(4n+1):nth-last-child(-n+4), &:nth-child(4n+1):nth-last-child(-n+4) ~ div {
      @include media-query(sm) {
        border-bottom: 0;
      }
    }

    &:last-child {
      @include media-query(xs) {
        border-bottom: 0;
      }
    }

    a {
      padding: 15px 0;
      display: inline;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

