// Books
// =====
// Edit user books
.#{$m}-books {
  &__row {
    &:last-of-type {
      .o-form-row {
        margin-bottom: 2rem;
      }
    }
  }

  &__addon {
    align-content: flex-start;
    padding-top: rem(43px);
  }
}
