// Input
// ====
// Basic input form
.o-input {
  // get basic settings
  $ctx: map-get($input-font-sizes, base);
  $paddings-base: map-get($input-paddings, base);
  $paddings-large: map-get($input-paddings, large);
  $line-height-base: map-get($input-line-heights, base);
  $color: map-get($colors-form, input-color);
  $border-color: map-get($colors-form, input-border);
  $background: map-get($colors-form, input-bg);

  padding: em(map-get($paddings-base, vertical), $ctx) em(map-get($paddings-base, horizontal), $ctx);
  background-color: $background;
  border: 1px solid $border-color;
  border-width: 1px 0 0 1px;
  color: $color;
  font-size: em($ctx);
  line-height: $line-height-base;

  @include on-event() {
    outline: 0;
  }

  &--lg {
    padding: em(map-get($paddings-large, vertical), $ctx) em(map-get($paddings-large, horizontal), $ctx);
  }

  &--border {
    border-width: 1px;
  }

  &.textfield {
    height: 150px;
  }
}
