// Input group
// ===========
.u-input-group {
  position: relative;
  display: table;
  width: 100%;
  &.validation-error {
    span label {
      background-color: #FF9F9F;
      border: 1px #FF0000 solid;
      color: black;
    }
  }


  &.show-all-container {
    padding-top: 0.5em;
    display: flex;
    flex-flow: row-reverse;
    @include media-range(phone) {
      flex-flow: row;
      span label {
        margin-left: 0;
        margin-right: .73333rem;
      }
    }
    .u-input-group-btn {
      width: initial;
    }
  }
}

.u-input-group-btn {
  position: relative;
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;

  &--top {
    vertical-align: top;
    padding-top: 3px;
  }

  &.facebook {
    padding-top: 0.1667rem !important;
  }
}
