// Layout 2-col
// ============
// Custom layout with fixed aside

.l-2col {
  position: relative;
  min-height: rem(350px);

  &__main {
    .container {
      position: relative; // for background elements purposes
    }
  }

  // custom width for cms content with aside offset
  &__cms {
    position: relative;

    @include media-query(md) {
      margin-right: rem($l-aside);
      padding-right: 2 * gutter($susy-fixed);
    }
  }

  &__aside {
    // make fake container to keep proportions
    @include grid-make-default-container();

    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }

  .aside-main {
    position: sticky;
    top: rem(10px);
    margin-bottom: rem(20px);
    float: right;
    min-height: 100px;
    width: rem($l-aside);
    background: gray;
    pointer-events: all;
  }
}
