// Accordion
// =====
.c-accordion {
  $b: &;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      list-style: none;
    }
  }

  &__item {
    margin-bottom: rem(20px);

    @include media-range(phone) {
      margin-bottom: rem(10px);
    }
  }

  &__icon-hide {
    display: none;
  }

  &__icon-show {
    display: inline-block;
  }

  &__header {
    & {
      display: table;
      width: 100%;
      padding: rem(30px);
      background-color: color(white);
      color: color(blue-stone);
      cursor: pointer;

      @include media-range(phone) {
        padding: em(15px);
      }
    }

    .o-icon {
      margin-left: em(18px);
      width: em(20px);
      height: em(20px);
      color: color(blue-stone);

      @include media-range(phone) {
        width: em(17px);
        height: em(17px);
      }
    }

    &:hover,
    &.is-open {
      background-color: color(blue-stone);
      color: color(white);

      .o-icon,
      #{$b}__title,
      #{$b}__addon {
        color: color(white);
      }
    }

    &.is-open {
      #{$b}__icon-show {
        display: none;
      }

      #{$b}__icon-hide {
        display: inline-block;
      }
    }
  }

  &__title {
    display: table-cell;
    color: color(blue-stone);

    @include media-range(phone) {
      line-height: 1.26;
    }
  }

  &__addon {
    width: 1%;
    white-space: nowrap;
    display: table-cell;
    vertical-align: middle;
    color: color(blue-stone);
  }

  &__desc {
    @include transition();

    height: 0;
    overflow: hidden;
    backface-visibility: hidden;
    will-change: height;
  }

  &__wrapper {
    padding: em(30px);
    margin: 0;
    background-color: color(black-squeeze);
    color: color(black);
    line-height: 1.26;

    @include media-range(phone) {
      padding: em(15px);
    }

    .ce-intext.ce-left ol,
    .ce-intext.ce-left ul {
      overflow: visible;
    }
  }
}
